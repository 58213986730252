import { AdvertisementType } from 'types/__generated__/types';
import { FormValidationSchema } from 'utils/form-utils';
import * as Yup from 'yup';
import { AdvertisementForm } from './AdvertisementForm.utils';

export const noActionAdvertisementFormDefaults = (): AdvertisementForm => ({
  type: AdvertisementType.NoActionAd,
  cpa: 0,
  brandId: '',
  name: '',
  category: 'Electronics',
  appDownload: {
    androidId: '',
    appleId: null,
    pcLink: '',
  },
  coupon: {
    couponDescription: '',
    couponImages: '',
    couponLabel: '',
  },
  internal: {
    internalHtml5Url: '',
    internalLabel: '',
    internalUnityUrl: '',
  },
  leadGen: { affiliateUrl: '' },
  video: { videoUrl: '' },
  noAction: { noActionLabel: '' },
});

export const mapNoActionAdvertisementToInput = ({ ...advertisement }: AdvertisementForm, brandId: string) => {
  return {
    ...advertisement,
    brandId,
  };
};

export const validationSchema = (): FormValidationSchema<AdvertisementForm> => {
  return Yup.object().shape({
    brandId: Yup.string(),
    name: Yup.string(),
    category: Yup.string(),
    cpa: Yup.number(),
    type: Yup.string(),
    appDownload: Yup.object().shape({
      androidId: Yup.string().nullable(),
      appleId: Yup.number().nullable(),
      pcLink: Yup.string()
        .matches(/[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:/?#[\]@!\\$&'\\(\\)\\*\\+,;=.]+$/, {
          message: 'PC link is invalid.',
          excludeEmptyString: true,
        })
        .nullable(),
    }),
    coupon: Yup.object().shape({
      couponImages: Yup.string().nullable(),
      couponDescription: Yup.string().nullable(),
      couponLabel: Yup.string().nullable(),
    }),
    internal: Yup.object().shape({
      internalHtml5Url: Yup.string().nullable(),
      internalUnityUrl: Yup.string().nullable(),
      internalLabel: Yup.string().nullable(),
    }),
    leadGen: Yup.object().shape({ affiliateUrl: Yup.string().nullable() }),
    noAction: Yup.object().shape({ noActionLabel: Yup.string().nullable() }),
    video: Yup.object().shape({ videoUrl: Yup.string().nullable() }),
  });
};
