import { useMemo } from 'react';
import { Column } from 'react-table';
import { Link } from 'react-router-dom';
import { advertisementRoutes } from 'routing';
import capitalize from 'lodash/capitalize';
import { AdvertisementItemFragment } from './__generated__/advertisements-list-page.hooks';

export function useAdvertisementsTableColumns(onDelete?: (id: string) => void): Column<AdvertisementItemFragment>[] {
  return useMemo<Column<AdvertisementItemFragment>[]>(
    () => [
      {
        Header: 'Advertisement Name',
        accessor: 'name',
      },
      {
        Header: 'Type',
        id: 'type',
        accessor: row => capitalize(row.type).replace(/_/g, ' '),
      },
      {
        Header: 'Category',
        id: 'category',
        // @ts-ignore
        accessor: row => capitalize(row.category).replace(/_/g, ' '),
      },
      {
        Header: '',
        id: 'edit',
        width: '6%',
        disableSortBy: true,
        Cell: ({ row }: any) => {
          const { id } = row.original;
          return (
            <div className="row-actions">
              <Link
                className="link-edit"
                title="Edit"
                to={`${advertisementRoutes.ADVERTISEMENT_EDIT(id)}?brandId=${row?.brandId}`}
              >
                Edit
              </Link>
              {onDelete && (
                <span className="link-delete" title="Delete" onClick={() => onDelete(id)}>
                  Delete
                </span>
              )}
            </div>
          );
        },
      },
    ],
    [onDelete],
  );
}
