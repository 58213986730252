import gql from 'graphql-tag';

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** LocalDate (YYYY-MM-DD) */
  LocalDate: any;
  /** Arbitrary object */
  Object: any;
};

export type AccessInfo = {
  __typename?: 'AccessInfo';
  claims: Scalars['Object'];
  shops?: Maybe<Array<Maybe<ShopAccess>>>;
  games?: Maybe<Array<Maybe<ShopAccess>>>;
  roles: Array<Scalars['String']>;
};

export type Address = {
  __typename?: 'Address';
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  zipCode: Scalars['String'];
  country: Scalars['String'];
  state?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  zipCode: Scalars['String'];
  country: Scalars['String'];
  state?: Maybe<Scalars['String']>;
};

export type AdImpressionPriorToBuyingMetric = {
  session: MetricSession;
  gameId: Scalars['String'];
  /** clicks prior product buying */
  clickCount: Scalars['Int'];
};

export type Advertisement = {
  __typename?: 'Advertisement';
  id: Scalars['ID'];
  name: Scalars['String'];
  brandId: Scalars['String'];
  category?: Maybe<Scalars['String']>;
  type: AdvertisementType;
  cpa?: Maybe<Scalars['Float']>;
  appDownload?: Maybe<AdvertisementAppDownload>;
  video?: Maybe<AdvertisementVideo>;
  leadGen?: Maybe<AdvertisementLeadGen>;
  coupon?: Maybe<AdvertisementCoupon>;
  noAction?: Maybe<AdvertisementNoAction>;
  internal?: Maybe<AdvertisementInternal>;
};

export type AdvertisementAppDownload = {
  __typename?: 'AdvertisementAppDownload';
  appleId?: Maybe<Scalars['Float']>;
  androidId?: Maybe<Scalars['String']>;
  pcLink?: Maybe<Scalars['String']>;
};

export type AdvertisementAppDownloadInput = {
  appleId?: Maybe<Scalars['Float']>;
  androidId?: Maybe<Scalars['String']>;
  pcLink?: Maybe<Scalars['String']>;
};

export type AdvertisementCoupon = {
  __typename?: 'AdvertisementCoupon';
  couponLabel?: Maybe<Scalars['String']>;
  couponDescription?: Maybe<Scalars['String']>;
  couponImages?: Maybe<Scalars['String']>;
};

export type AdvertisementCouponInput = {
  couponLabel?: Maybe<Scalars['String']>;
  couponDescription?: Maybe<Scalars['String']>;
  couponImages?: Maybe<Scalars['String']>;
};

export type AdvertisementInternal = {
  __typename?: 'AdvertisementInternal';
  internalLabel?: Maybe<Scalars['String']>;
  internalHtml5Url?: Maybe<Scalars['String']>;
  internalUnityUrl?: Maybe<Scalars['String']>;
};

export type AdvertisementInternalInput = {
  internalLabel?: Maybe<Scalars['String']>;
  internalHtml5Url?: Maybe<Scalars['String']>;
  internalUnityUrl?: Maybe<Scalars['String']>;
};

export type AdvertisementLeadGen = {
  __typename?: 'AdvertisementLeadGen';
  affiliateUrl?: Maybe<Scalars['String']>;
};

export type AdvertisementLeadGenInput = {
  affiliateUrl?: Maybe<Scalars['String']>;
};

export type AdvertisementNoAction = {
  __typename?: 'AdvertisementNoAction';
  noActionLabel?: Maybe<Scalars['String']>;
};

export type AdvertisementNoActionInput = {
  noActionLabel?: Maybe<Scalars['String']>;
};

export type AdvertisementPage = Page & {
  __typename?: 'AdvertisementPage';
  sorting?: Maybe<Array<SortField>>;
  paging: Paging;
  nodes: Array<Advertisement>;
};

export enum AdvertisementType {
  AppDownload = 'APP_DOWNLOAD',
  VideoPromo = 'VIDEO_PROMO',
  LeadGen = 'LEAD_GEN',
  Coupon = 'COUPON',
  NoActionAd = 'NO_ACTION_AD',
  Internal = 'INTERNAL',
}

export type AdvertisementVideo = {
  __typename?: 'AdvertisementVideo';
  videoUrl?: Maybe<Scalars['String']>;
};

export type AdvertisementVideoInput = {
  videoUrl?: Maybe<Scalars['String']>;
};

export type BankAccount = {
  __typename?: 'BankAccount';
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  zipCode: Scalars['String'];
  country: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  organization: Organization;
  name: Scalars['String'];
  bankName: Scalars['String'];
  routingNumber: Scalars['String'];
  accountNumber: Scalars['String'];
  isDefault: Scalars['Boolean'];
  phone?: Maybe<Scalars['String']>;
  owner: UserInfo;
};

export type Banner = {
  __typename?: 'Banner';
  bigUrl?: Maybe<Scalars['String']>;
  tallUrl?: Maybe<Scalars['String']>;
  smallUrl?: Maybe<Scalars['String']>;
};

export type BillingAddress = {
  __typename?: 'BillingAddress';
  fullName: Scalars['String'];
  address1: Scalars['String'];
  address2: Scalars['String'];
  zipCode: Scalars['String'];
};

export type BillingAddressInput = {
  fullName: Scalars['String'];
  address1: Scalars['String'];
  address2: Scalars['String'];
  zipCode: Scalars['String'];
};

export type Campaign = {
  __typename?: 'Campaign';
  id: Scalars['ID'];
  name: Scalars['String'];
  category?: Maybe<Scalars['String']>;
  type: CampaignType;
  status: CampaignStatus;
  durationStartDate?: Maybe<Scalars['DateTime']>;
  durationEndDate?: Maybe<Scalars['DateTime']>;
  shop?: Maybe<ShopInfo>;
  shopBrand?: Maybe<ShopBrand>;
  product?: Maybe<CampaignProduct>;
  productListingId?: Maybe<Scalars['String']>;
  media?: Maybe<CampaignMedia>;
  video?: Maybe<CampaignVideo>;
  demographic: CampaignDemographic;
  duration: CampaignDuration;
  budget?: Maybe<CampaignBudget>;
  game?: Maybe<CampaignGame>;
  location?: Maybe<CampaignLocation>;
  reward?: Maybe<CampaignReward>;
  statistics?: Maybe<CampaignStatistics>;
  extras: CampaignExtras;
  appDownloadIds?: Maybe<CampaignAppDownloadIds>;
  internalUrl?: Maybe<Scalars['String']>;
};

export type CampaignAppDownloadIds = {
  __typename?: 'CampaignAppDownloadIds';
  appleId?: Maybe<Scalars['Float']>;
  androidId?: Maybe<Scalars['String']>;
  pcLink?: Maybe<Scalars['String']>;
};

export type CampaignAppDownloadIdsInput = {
  appleId?: Maybe<Scalars['Float']>;
  androidId?: Maybe<Scalars['String']>;
  pcLink?: Maybe<Scalars['String']>;
};

export type CampaignBudget = {
  __typename?: 'CampaignBudget';
  option?: Maybe<CampaignBudgetOption>;
  maxSpend: Scalars['Float'];
  maxDailySpend: Scalars['Float'];
  limitOfImpressions: Scalars['Int'];
};

export type CampaignBudgetInput = {
  option?: Maybe<CampaignBudgetOption>;
  maxSpend: Scalars['Float'];
  maxDailySpend: Scalars['Float'];
  limitOfImpressions: Scalars['Int'];
};

export enum CampaignBudgetOption {
  Normal = 'NORMAL',
  Featured = 'FEATURED',
}

export type CampaignDemographic = {
  __typename?: 'CampaignDemographic';
  gender?: Maybe<Scalars['String']>;
  minAge: Scalars['Int'];
  maxAge: Scalars['Int'];
  contentCategory?: Maybe<ContentCategory>;
};

export type CampaignDemographicInput = {
  gender?: Maybe<Scalars['String']>;
  minAge: Scalars['Int'];
  maxAge: Scalars['Int'];
  contentCategory?: Maybe<ContentCategory>;
};

export type CampaignDuration = {
  __typename?: 'CampaignDuration';
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  inventory?: Maybe<Scalars['Int']>;
  runUntilStockZero?: Maybe<Scalars['Boolean']>;
};

export type CampaignDurationInput = {
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  inventory?: Maybe<Scalars['Int']>;
  runUntilStockZero?: Maybe<Scalars['Boolean']>;
};

export type CampaignExtras = {
  __typename?: 'CampaignExtras';
  moreExposure?: Maybe<Scalars['Boolean']>;
  isTodayDeal?: Maybe<Scalars['Boolean']>;
};

export type CampaignExtrasInput = {
  moreExposure?: Maybe<Scalars['Boolean']>;
  isTodayDeal?: Maybe<Scalars['Boolean']>;
};

export type CampaignGame = {
  __typename?: 'CampaignGame';
  games?: Maybe<Array<GameInfo>>;
  gameGenres?: Maybe<Array<Scalars['String']>>;
};

export type CampaignGameInput = {
  gameIds?: Maybe<Array<Scalars['String']>>;
  gameGenres?: Maybe<Array<Scalars['String']>>;
};

export type CampaignInfo = {
  __typename?: 'CampaignInfo';
  id: Scalars['ID'];
  name: Scalars['String'];
  category?: Maybe<Scalars['String']>;
  type: CampaignType;
  status: CampaignStatus;
  durationStartDate?: Maybe<Scalars['DateTime']>;
  durationEndDate?: Maybe<Scalars['DateTime']>;
};

export type CampaignLocation = {
  __typename?: 'CampaignLocation';
  country?: Maybe<Scalars['String']>;
  states?: Maybe<Array<Scalars['String']>>;
};

export type CampaignLocationInput = {
  country?: Maybe<Scalars['String']>;
  states?: Maybe<Array<Scalars['String']>>;
};

export type CampaignMedia = {
  __typename?: 'CampaignMedia';
  banner?: Maybe<Scalars['String']>;
  vertical?: Maybe<Scalars['String']>;
  tile?: Maybe<Scalars['String']>;
};

export type CampaignMediaInput = {
  banner?: Maybe<Scalars['String']>;
  vertical?: Maybe<Scalars['String']>;
  tile?: Maybe<Scalars['String']>;
};

export type CampaignPage = Page & {
  __typename?: 'CampaignPage';
  sorting?: Maybe<Array<SortField>>;
  paging: Paging;
  nodes: Array<Campaign>;
};

export type CampaignProduct = {
  __typename?: 'CampaignProduct';
  product: ProductInfo;
  promotion?: Maybe<CampaignProductPromotion>;
};

export type CampaignProductInput = {
  productId: Scalars['String'];
  promotion?: Maybe<CampaignProductPromotionInput>;
};

export type CampaignProductPromotion = {
  __typename?: 'CampaignProductPromotion';
  promotionType?: Maybe<PromotionType>;
  offerType?: Maybe<OfferType>;
  offerCopy?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Float']>;
};

export type CampaignProductPromotionInput = {
  promotionType?: Maybe<PromotionType>;
  offerType?: Maybe<OfferType>;
  offerCopy?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Float']>;
};

export type CampaignReward = {
  __typename?: 'CampaignReward';
  scutiPercentage: Scalars['Float'];
};

export type CampaignRewardInput = {
  scutiPercentage?: Maybe<Scalars['Float']>;
};

export type CampaignStatistics = {
  __typename?: 'CampaignStatistics';
  totalSpend: Scalars['Float'];
  totalRevenue: Scalars['Float'];
  roi: Scalars['Float'];
};

export enum CampaignStatus {
  Pending = 'PENDING',
  Paused = 'PAUSED',
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Deleted = 'DELETED',
}

export enum CampaignType {
  Product = 'PRODUCT',
  ProductListing = 'PRODUCT_LISTING',
  Video = 'VIDEO',
  Banner = 'BANNER',
  Takeover = 'TAKEOVER',
  InternalUrl = 'INTERNAL_URL',
  AppDownload = 'APP_DOWNLOAD',
}

export type CampaignVideo = {
  __typename?: 'CampaignVideo';
  videoUrl: Scalars['String'];
};

export type CampaignVideoInput = {
  videoUrl: Scalars['String'];
};

export enum CartAction {
  Add = 'ADD',
  Remove = 'REMOVE',
  ChangeCount = 'CHANGE_COUNT',
  Leave = 'LEAVE',
}

export type CartInput = {
  gameId: Scalars['String'];
  items: Array<CheckoutItemInput>;
  shippingInfo: ShippingInformationInput;
};

export type CartMetric = {
  session: MetricSession;
  gameId: Scalars['String'];
  action: CartAction;
  count: Scalars['Int'];
};

export type CheckoutEncryption = {
  __typename?: 'CheckoutEncryption';
  keyId: Scalars['String'];
  publicKey: Scalars['String'];
};

export type CheckoutInput = {
  gameId: Scalars['String'];
  items: Array<CheckoutItemInput>;
  shippingInfo: ShippingInformationInput;
  source: PaymentSource;
  scutisToApply?: Maybe<Scalars['Int']>;
};

export type CheckoutItemInput = {
  variantId: Scalars['String'];
  offerId: Scalars['String'];
  quantity: Scalars['Int'];
};

export enum ContentCategory {
  RatingPending = 'RATING_PENDING',
  EarlyChildhood = 'EARLY_CHILDHOOD',
  Everyone = 'EVERYONE',
  Everyone_10Plus = 'EVERYONE_10_PLUS',
  Teen = 'TEEN',
  AdultsOnly = 'ADULTS_ONLY',
  Mature = 'MATURE',
}

export type CreateBankAccountInput = {
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  zipCode: Scalars['String'];
  country: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  bankName: Scalars['String'];
  routingNumber: Scalars['String'];
  accountNumber: Scalars['String'];
  isDefault: Scalars['Boolean'];
  organizationId: Scalars['String'];
};

export type CreditCard = {
  expiryMonth: Scalars['Int'];
  expiryYear: Scalars['Int'];
  name: Scalars['String'];
  encrypted: EncryptedInput;
  billingAddress: AddressInput;
};

export type Currency = {
  __typename?: 'Currency';
  name: Scalars['String'];
  thumbnail?: Maybe<Scalars['String']>;
  /** How much vbucks do I get for 1 dollar? */
  exchangeRate: Scalars['Float'];
  /** How much vbucks do I get for 1 scuti? */
  scutiExchangeRate: Scalars['Float'];
};

export type CurrencyInput = {
  name: Scalars['String'];
  thumbnail?: Maybe<Scalars['String']>;
  /** Exchange rate for US dollar */
  exchangeRate: Scalars['Float'];
};

export type EditShopBrandsInput = {
  id: Scalars['ID'];
  brands: Array<ShopBrandInput>;
};

export type EncryptedInput = {
  encryptedData: Scalars['String'];
  keyId: Scalars['String'];
};

export type EngagementWithProductMetric = {
  session: MetricSession;
  gameId: Scalars['String'];
  offerId: Scalars['String'];
  /** Time in seconds */
  duration: Scalars['Int'];
  clickCount: Scalars['Int'];
};

export enum EntityType {
  Shop = 'SHOP',
  Game = 'GAME',
  Organization = 'ORGANIZATION',
}

export enum Filter_Type {
  Ieq = 'IEQ',
  Eq = 'EQ',
  Like = 'LIKE',
  Contains = 'CONTAINS',
  Gt = 'GT',
  Lt = 'LT',
  GtEq = 'GT_EQ',
  LtEq = 'LT_EQ',
  In = 'IN',
  NotIn = 'NOT_IN',
  Between = 'BETWEEN',
  Includes = 'INCLUDES',
  IsNull = 'IS_NULL',
  IsNotNull = 'IS_NOT_NULL',
}

export type FilterInput = {
  name: Scalars['String'];
  operator: Filter_Type;
  value?: Maybe<Array<Scalars['String']>>;
  allowNulls?: Maybe<Scalars['Boolean']>;
};

export type FulfillmentMethod = {
  __typename?: 'FulfillmentMethod';
  id: Scalars['ID'];
  shop: Shop;
  name: Scalars['String'];
  type: FulfillmentType;
};

export type FulfillmentMethodInput = {
  shopId: Scalars['String'];
  name: Scalars['String'];
  type: FulfillmentType;
};

export enum FulfillmentType {
  Shipping = 'SHIPPING',
  Pickup = 'PICKUP',
  Digital = 'DIGITAL',
}

export type Game = {
  __typename?: 'Game';
  id: Scalars['ID'];
  name: Scalars['String'];
  category: Scalars['String'];
  tags: Array<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  contentCategory: ContentCategory;
  owner: UserInfo;
  admins: Array<UserWithRoles>;
  ageFrom: Scalars['Int'];
  ageTo: Scalars['Int'];
  genderPercent?: Maybe<GenderPercent>;
};

export type GameInfo = {
  __typename?: 'GameInfo';
  id: Scalars['ID'];
  name: Scalars['String'];
  category: Scalars['String'];
  tags: Array<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  contentCategory: ContentCategory;
};

export type GameInput = {
  name: Scalars['String'];
  category: Scalars['String'];
  tags?: Maybe<Array<Scalars['String']>>;
  thumbnail?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
  currency?: Maybe<CurrencyInput>;
  ageFrom: Scalars['Int'];
  ageTo: Scalars['Int'];
  contentCategory: ContentCategory;
  genderPercent?: Maybe<GenderPercentInput>;
  organizationId?: Maybe<Scalars['String']>;
};

export type GamePage = Page & {
  __typename?: 'GamePage';
  sorting?: Maybe<Array<SortField>>;
  paging: Paging;
  nodes: Array<Game>;
};

export type GenderPercent = {
  __typename?: 'GenderPercent';
  male: Scalars['Float'];
  female: Scalars['Float'];
};

export type GenderPercentInput = {
  male: Scalars['Float'];
  female: Scalars['Float'];
};

export type Invite = {
  __typename?: 'Invite';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  email: Scalars['String'];
  roles: Array<Role>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  accepted: Scalars['Boolean'];
  hide: Scalars['Boolean'];
};

export type InviteInput = {
  email: Scalars['String'];
  roles: Array<Role>;
  twoFactorEnabled: Scalars['Boolean'];
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<EntityType>;
};

export type InvitePage = Page & {
  __typename?: 'InvitePage';
  sorting?: Maybe<Array<SortField>>;
  paging: Paging;
  nodes: Array<Invite>;
};

export type Metric = {
  __typename?: 'Metric';
  type: MetricType;
  gameId?: Maybe<Scalars['String']>;
  when: Scalars['Object'];
};

export type MetricSession = {
  id: Scalars['String'];
  platform: Platform;
  device: Scalars['String'];
};

export enum MetricType {
  ViewOfferDetails = 'VIEW_OFFER_DETAILS',
  OfferImpression = 'OFFER_IMPRESSION',
  AdImpressionsPriorToBuying = 'AD_IMPRESSIONS_PRIOR_TO_BUYING',
  TimeSpentPerusingItems = 'TIME_SPENT_PERUSING_ITEMS',
  EngagementWithProduct = 'ENGAGEMENT_WITH_PRODUCT',
  SocializationOfBrand = 'SOCIALIZATION_OF_BRAND',
  SdkInit = 'SDK_INIT',
  Cart = 'CART',
  ViewBanner = 'VIEW_BANNER',
  ViewVideo = 'VIEW_VIDEO',
}

export type Mutation = {
  __typename?: 'Mutation';
  /** [SCUTI_USER] - update my personal information */
  updateMyPersonalInfo: PersonalInfo;
  /** [SCUTI_USER] - update my preferences */
  updateMyPreferences: Preferences;
  /** [SCUTI_USER] - update my shipping information */
  updateMyShippingInfo: ShippingInfo;
  /** [SCUTI_USER] - exchange my scutis */
  exchange: ScutiExchange;
  /** [SHOP_OWNER,SCUTI_ADMIN] - create shop */
  createShop: Shop;
  /** [SHOP_OWNER] - delete shop by id */
  deleteShop?: Maybe<Scalars['String']>;
  /** [SHOP_OWNER,SCUTI_ADMIN,SHOP_ADMIN] - update shop */
  updateShop?: Maybe<Shop>;
  /** [SHOP_OWNER,SCUTI_ADMIN,SHOP_ADMIN] - edit shop brands */
  editShopBrands: Array<ShopBrand>;
  /** [SHOP_OWNER,SCUTI_ADMIN,SHOP_ADMIN] - sync shop with eBridge data */
  syncEbridge?: Maybe<SyncEbridgeResult>;
  inviteToShop: Array<Role>;
  removeFromShop: Scalars['Boolean'];
  /** [SCUTI_ADMIN,SHOP_ADMIN,SHOP_OWNER] - create product */
  createProduct: Product;
  /** [SCUTI_ADMIN,SHOP_ADMIN,SHOP_OWNER] - update product */
  updateProduct: Product;
  /** [SCUTI_ADMIN,SHOP_ADMIN,SHOP_OWNER] - delete product by id */
  deleteProduct?: Maybe<Scalars['String']>;
  /** [SCUTI_ADMIN,GAME_OWNER] - create/generate game token */
  createGameToken: Token;
  /** [SCUTI_ADMIN,GAME_OWNER] - delete game token(s) by game id */
  deleteGameToken: Scalars['String'];
  /** [SCUTI_ADMIN,GAME_OWNER] - create game for organization */
  createGame: Game;
  /** [SCUTI_ADMIN,GAME_OWNER,GAME_ADMIN] - update game */
  updateGame: Game;
  /** [SCUTI_ADMIN,GAME_OWNER] - delete game by id */
  deleteGame?: Maybe<Scalars['String']>;
  inviteToGame: Array<Role>;
  removeFromGame: Scalars['Boolean'];
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - create new campaign */
  createCampaign: Campaign;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - update campaign by id */
  updateCampaign: Campaign;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - delete campaign by id */
  deleteCampaign?: Maybe<Scalars['String']>;
  /** [SCUTI_ADMIN] - set today deals by campaign ids */
  setTodayDeals: Array<Campaign>;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - toggle campaign status (pause/unpause) by id */
  toggleCampaign: Campaign;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - create new advertisement */
  createAdvertisement: Advertisement;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - update advertisement by id */
  updateAdvertisement: Advertisement;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - delete advertisement by id */
  deleteAdvertisement?: Maybe<Scalars['String']>;
  /** [SCUTI_ADMIN,SHOP_OWNER] - create new bank account for organization */
  createBankAccount: BankAccount;
  /** [SCUTI_ADMIN,SHOP_OWNER] - update bank account for organization */
  updateBankAccount: BankAccount;
  /** [SCUTI_ADMIN,SHOP_OWNER] - delete bank account for organization */
  deleteBankAccount?: Maybe<Scalars['String']>;
  /** @deprecated subject to change */
  createFulfillmentMethod: FulfillmentMethod;
  /** @deprecated subject to change */
  updateFulfillmentMethod: FulfillmentMethod;
  /** @deprecated subject to change */
  deleteFulfillmentMethod?: Maybe<Scalars['String']>;
  createPaymentMethod: PaymentMethod;
  updatePaymentMethod: PaymentMethod;
  deletePaymentMethod?: Maybe<Scalars['String']>;
  /** [SCUTI_USER] - activate/claim my rewards */
  activateRewards: Array<Reward>;
  /** [SCUTI_ADMIN,SHOP_OWNER,GAME_OWNER,ORGANIZATION_OWNER] - create organization/business details */
  createOrganization: Organization;
  /** [ORGANIZATION_OWNER,SCUTI_ADMIN] - update organization/business details */
  updateOrganization: Organization;
  /**
   * [SCUTI_ADMIN,ORGANIZATION_OWNER,SHOP_OWNER,GAME_OWNER] - pass organization
   * ownership. Also pass ownership for organization shops and games.
   */
  setOwner: UserInfo;
  /** [SCUTI_USER,SCUTI_ADMIN,SHOP_ADMIN,GAME_ADMIN,SHOP_OWNER,GAME_OWNER,ORGANIZATION_OWNER] - accept invite */
  acceptInvite?: Maybe<Invite>;
  invite: Invite;
  resendInviteMail: Scalars['Boolean'];
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - set order status by order id */
  setOrderStatus: OrderHistory;
  /** [SCUTI_USER] - checkout shopping cart */
  checkout: Receipt;
  /** [SCUTI_USER] - calculate cart */
  calculateCart: Receipt;
  /** [SCUTI_USER] - create credit card */
  createOrReplaceMyCard: UserCard;
  /** [SCUTI_USER] - delete my cards by card ids */
  deleteMyCards: Array<UserCard>;
  /** [SCUTI_USER] - set my default card by id */
  setMyDefaultCard: UserCard;
  /** [SCUTI_USER?] - client metrics */
  metricViewOfferDetails: Metric;
  /** [SCUTI_USER?] - client metrics */
  metricAdImpressionPriorToBuying: Metric;
  /** [SCUTI_USER?] - client metrics */
  metricTimeSpentPerusing: Metric;
  /** [SCUTI_USER?] - client metrics */
  metricEngagementWithProduct: Metric;
  /** [SCUTI_USER?] - client metrics */
  metricSocializationOfBrand: Metric;
  /** [SCUTI_USER?] - client metrics */
  metricSDKInit: Metric;
  /** [SCUTI_USER?] - client metrics */
  metricCart: Metric;
  /** [SCUTI_USER?] - client metrics */
  metricViewBanner: Metric;
  /** [SCUTI_USER?] - client metrics */
  metricViewVideo: Metric;
};

export type MutationUpdateMyPersonalInfoArgs = {
  input: PersonalInformationInput;
};

export type MutationUpdateMyPreferencesArgs = {
  input: PreferencesInput;
};

export type MutationUpdateMyShippingInfoArgs = {
  input: ShippingInformationInput;
};

export type MutationExchangeArgs = {
  gameId: Scalars['String'];
  amount: Scalars['Int'];
};

export type MutationCreateShopArgs = {
  input: ShopInput;
};

export type MutationDeleteShopArgs = {
  id: Scalars['String'];
};

export type MutationUpdateShopArgs = {
  input: UpdateShopInput;
};

export type MutationEditShopBrandsArgs = {
  input: EditShopBrandsInput;
};

export type MutationSyncEbridgeArgs = {
  input: SyncEbridgeInput;
};

export type MutationInviteToShopArgs = {
  user: UserRoleInput;
  id: Scalars['String'];
};

export type MutationRemoveFromShopArgs = {
  email: Scalars['String'];
  shopId: Scalars['String'];
};

export type MutationCreateProductArgs = {
  input: ProductInput;
};

export type MutationUpdateProductArgs = {
  input: UpdateProductInput;
};

export type MutationDeleteProductArgs = {
  id: Scalars['String'];
};

export type MutationCreateGameTokenArgs = {
  id: Scalars['String'];
};

export type MutationDeleteGameTokenArgs = {
  id: Scalars['String'];
};

export type MutationCreateGameArgs = {
  input: GameInput;
};

export type MutationUpdateGameArgs = {
  input: UpdateGameInput;
};

export type MutationDeleteGameArgs = {
  id: Scalars['String'];
};

export type MutationInviteToGameArgs = {
  user: UserRoleInput;
  id: Scalars['String'];
};

export type MutationRemoveFromGameArgs = {
  email: Scalars['String'];
  gameId: Scalars['String'];
};

export type MutationCreateCampaignArgs = {
  input: NewCampaignInput;
};

export type MutationUpdateCampaignArgs = {
  input: UpdateCampaignInput;
};

export type MutationDeleteCampaignArgs = {
  id: Scalars['String'];
};

export type MutationSetTodayDealsArgs = {
  ids: Array<Scalars['String']>;
  shopId: Scalars['String'];
};

export type MutationToggleCampaignArgs = {
  input: ToggleCampaignInput;
};

export type MutationCreateAdvertisementArgs = {
  input: NewAdvertisementInput;
};

export type MutationUpdateAdvertisementArgs = {
  input: UpdateAdvertisementInput;
};

export type MutationDeleteAdvertisementArgs = {
  id: Scalars['String'];
};

export type MutationCreateBankAccountArgs = {
  input: CreateBankAccountInput;
};

export type MutationUpdateBankAccountArgs = {
  input: UpdateBankAccountInput;
};

export type MutationDeleteBankAccountArgs = {
  id: Scalars['String'];
  organizationId: Scalars['String'];
};

export type MutationCreateFulfillmentMethodArgs = {
  input: FulfillmentMethodInput;
};

export type MutationUpdateFulfillmentMethodArgs = {
  input: UpdateFulfillmentMethodInput;
};

export type MutationDeleteFulfillmentMethodArgs = {
  id: Scalars['String'];
};

export type MutationCreatePaymentMethodArgs = {
  input: PaymentMethodInput;
};

export type MutationUpdatePaymentMethodArgs = {
  input: UpdatePaymentMethodInput;
};

export type MutationDeletePaymentMethodArgs = {
  id: Scalars['String'];
};

export type MutationActivateRewardsArgs = {
  rewardId: Array<Scalars['String']>;
};

export type MutationCreateOrganizationArgs = {
  input: OrganizationInput;
};

export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};

export type MutationSetOwnerArgs = {
  userEmail: Scalars['String'];
  organizationId: Scalars['String'];
};

export type MutationAcceptInviteArgs = {
  inviteId: Scalars['String'];
};

export type MutationInviteArgs = {
  input: InviteInput;
};

export type MutationResendInviteMailArgs = {
  email: Scalars['String'];
};

export type MutationSetOrderStatusArgs = {
  status: OrderStatus;
  id: Scalars['String'];
};

export type MutationCheckoutArgs = {
  input: CheckoutInput;
};

export type MutationCalculateCartArgs = {
  input: CartInput;
};

export type MutationCreateOrReplaceMyCardArgs = {
  input: CreditCard;
};

export type MutationDeleteMyCardsArgs = {
  cardIds: Array<Scalars['String']>;
};

export type MutationSetMyDefaultCardArgs = {
  id: Scalars['String'];
};

export type MutationMetricViewOfferDetailsArgs = {
  input: OfferMetricBase;
};

export type MutationMetricAdImpressionPriorToBuyingArgs = {
  input: AdImpressionPriorToBuyingMetric;
};

export type MutationMetricTimeSpentPerusingArgs = {
  input: TimeSpentPerusingMetric;
};

export type MutationMetricEngagementWithProductArgs = {
  input: EngagementWithProductMetric;
};

export type MutationMetricSocializationOfBrandArgs = {
  input: SocializationOfBrandMetric;
};

export type MutationMetricSdkInitArgs = {
  input: SdkInitMetric;
};

export type MutationMetricCartArgs = {
  input: CartMetric;
};

export type MutationMetricViewBannerArgs = {
  input: UrlOfferMetricBase;
};

export type MutationMetricViewVideoArgs = {
  input: UrlOfferMetricBase;
};

export type NewAdvertisementInput = {
  name?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  cpa?: Maybe<Scalars['Float']>;
  appDownload?: Maybe<AdvertisementAppDownloadInput>;
  video?: Maybe<AdvertisementVideoInput>;
  leadGen?: Maybe<AdvertisementLeadGenInput>;
  coupon?: Maybe<AdvertisementCouponInput>;
  noAction?: Maybe<AdvertisementNoActionInput>;
  internal?: Maybe<AdvertisementInternalInput>;
  type: AdvertisementType;
  brandId: Scalars['String'];
};

export type NewCampaignInput = {
  name: Scalars['String'];
  category?: Maybe<Scalars['String']>;
  product?: Maybe<CampaignProductInput>;
  productListingId?: Maybe<Scalars['String']>;
  media?: Maybe<CampaignMediaInput>;
  video?: Maybe<CampaignVideoInput>;
  demographic: CampaignDemographicInput;
  duration: CampaignDurationInput;
  budget?: Maybe<CampaignBudgetInput>;
  game?: Maybe<CampaignGameInput>;
  location: CampaignLocationInput;
  reward?: Maybe<CampaignRewardInput>;
  extras: CampaignExtrasInput;
  shopBrandId?: Maybe<Scalars['ID']>;
  internalUrl?: Maybe<Scalars['String']>;
  appDownloadIds?: Maybe<CampaignAppDownloadIdsInput>;
  type: CampaignType;
  shopId: Scalars['String'];
};

export type Offer = {
  __typename?: 'Offer';
  id: Scalars['ID'];
  type: Scalars['String'];
  category?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  shop?: Maybe<ShopInfo>;
  product?: Maybe<OfferProduct>;
  brand?: Maybe<ShopBrand>;
  promotions: OfferPromotions;
  media: OfferMedia;
  review?: Maybe<OfferReview>;
  reward: OfferReward;
  createdAt: Scalars['DateTime'];
  internalUrl?: Maybe<Scalars['String']>;
  appleId?: Maybe<Scalars['Float']>;
  androidId?: Maybe<Scalars['String']>;
  pcLink?: Maybe<Scalars['String']>;
};

export type OfferCategoryCount = {
  __typename?: 'OfferCategoryCount';
  category: Scalars['String'];
  count: Scalars['Float'];
};

export type OfferMedia = {
  __typename?: 'OfferMedia';
  banner?: Maybe<Banner>;
  tile?: Maybe<Scalars['String']>;
  vertical?: Maybe<Scalars['String']>;
  videoUrl?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Scalars['String']>>;
};

export type OfferMetricBase = {
  session: MetricSession;
  gameId: Scalars['String'];
  offerId: Scalars['String'];
};

export type OfferPage = Page & {
  __typename?: 'OfferPage';
  sorting?: Maybe<Array<SortField>>;
  paging: Paging;
  nodes: Array<Offer>;
  seed?: Maybe<Scalars['Int']>;
};

export type OfferProduct = {
  __typename?: 'OfferProduct';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  category: Scalars['String'];
  tags?: Maybe<Array<Scalars['String']>>;
  images?: Maybe<Array<Scalars['String']>>;
  chargeTaxes: Scalars['Boolean'];
  shop: ShopInfo;
  variants: Array<ProductVariant>;
  price: Price;
  option1?: Maybe<Scalars['String']>;
  option2?: Maybe<Scalars['String']>;
  option3?: Maybe<Scalars['String']>;
};

export type OfferPromotions = {
  __typename?: 'OfferPromotions';
  isNew: Scalars['Boolean'];
  isLatest: Scalars['Boolean'];
  isMisc: Scalars['Boolean'];
  isHotPrice: Scalars['Boolean'];
  isHotItem: Scalars['Boolean'];
  isRecommended: Scalars['Boolean'];
  isBestSeller: Scalars['Boolean'];
  isSpecialOffer: Scalars['Boolean'];
  isFeatured: Scalars['Boolean'];
  isMoreExposure: Scalars['Boolean'];
  isTodayDeal: Scalars['Boolean'];
  isScuti: Scalars['Boolean'];
  specialOfferType?: Maybe<OfferType>;
  specialOfferText?: Maybe<Scalars['String']>;
};

export type OfferReview = {
  __typename?: 'OfferReview';
  score: Scalars['Float'];
  count: Scalars['Int'];
};

export type OfferReward = {
  __typename?: 'OfferReward';
  scutis?: Maybe<Scalars['Float']>;
  scutiPercentage?: Maybe<Scalars['Float']>;
};

export type OfferStatistics = {
  __typename?: 'OfferStatistics';
  byCategories: Array<OfferCategoryCount>;
  total: Scalars['Float'];
  newOffers: Scalars['Float'];
};

export enum OfferType {
  FreeShipping = 'FREE_SHIPPING',
  BuyOneGetOne = 'BUY_ONE_GET_ONE',
  GiftWithPurchase = 'GIFT_WITH_PURCHASE',
  FreeSamples = 'FREE_SAMPLES',
  LimitedTimeOffer = 'LIMITED_TIME_OFFER',
  TripWire = 'TRIP_WIRE',
}

export type Order = {
  __typename?: 'Order';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  user: UserInfo;
  game: GameInfo;
  shop: ShopInfo;
  amount: Scalars['Float'];
  shippingInformation?: Maybe<OrderShippingInformationSnapshot>;
  items: Array<OrderItem>;
  status: OrderStatus;
};

export type OrderCampaignSnapshot = {
  __typename?: 'OrderCampaignSnapshot';
  id: Scalars['ID'];
};

export type OrderFulfillmentMethodSnapshot = {
  __typename?: 'OrderFulfillmentMethodSnapshot';
  name: Scalars['String'];
  type: FulfillmentType;
};

export type OrderHistory = {
  __typename?: 'OrderHistory';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  user: UserInfo;
  status: OrderStatus;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  id: Scalars['ID'];
  product: OrderProductSnapshot;
  campaign: OrderCampaignSnapshot;
  fulfillmentMethod: OrderFulfillmentMethodSnapshot;
  quantity: Scalars['Float'];
  amount: Scalars['Float'];
  currency: Scalars['String'];
};

export type OrderPage = Page & {
  __typename?: 'OrderPage';
  sorting?: Maybe<Array<SortField>>;
  paging: Paging;
  nodes: Array<Order>;
};

export type OrderProductSnapshot = {
  __typename?: 'OrderProductSnapshot';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  reviewUrl?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  defaultImage?: Maybe<Scalars['String']>;
  option1?: Maybe<Scalars['String']>;
  option2?: Maybe<Scalars['String']>;
  option3?: Maybe<Scalars['String']>;
  variant: OrderProductVariantSnapshot;
  createdAt: Scalars['DateTime'];
};

export type OrderProductVariantSnapshot = {
  __typename?: 'OrderProductVariantSnapshot';
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  price: Price;
  compareAt?: Maybe<Price>;
  sku: Scalars['String'];
  barcode?: Maybe<Scalars['String']>;
  weight?: Maybe<Weight>;
  inStock: Scalars['Float'];
  option1?: Maybe<Scalars['String']>;
  option2?: Maybe<Scalars['String']>;
  option3?: Maybe<Scalars['String']>;
  variantPosition?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
};

export type OrderShippingInformationSnapshot = {
  __typename?: 'OrderShippingInformationSnapshot';
  phone?: Maybe<Scalars['String']>;
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  zipCode: Scalars['String'];
  country: Scalars['String'];
  state?: Maybe<Scalars['String']>;
};

export enum OrderStatus {
  Pending = 'PENDING',
  Shipping = 'SHIPPING',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED',
}

export enum PayoutStatus {
  PENDING = 'PENDING',
  ERROR = 'ERROR',
  PAID = 'PAID',
}

export type RefundOrderInput = {
  userId: Scalars['String'];
  orderId: Scalars['String'];
  items: Array<RefundOrderInputItem>;
};

export type UpdateOrderInput = {
  ebridgeOrderId?: Scalars['String'];
  vendorOrderId?: Scalars['String'];
  note?: Scalars['String'];
  payoutStatus?: PayoutStatus;
};

export type RefundOrderInputItem = {
  orderItemId: Scalars['String'];
  activated?: Scalars['Boolean'];
  currencyName?: Scalars['String'];
  refundQuantity?: Scalars['Float'];
  scuti?: Scalars['Float'];
  reason?: Scalars['String'];
};

export type Organization = {
  __typename?: 'Organization';
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  zipCode: Scalars['String'];
  country: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  website?: Maybe<Scalars['String']>;
  category: Scalars['String'];
  identificationNumber: Scalars['String'];
  owner: UserInfo;
  dbaName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type OrganizationInput = {
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  zipCode: Scalars['String'];
  country: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  website?: Maybe<Scalars['String']>;
  category: Scalars['String'];
  identificationNumber: Scalars['String'];
  dbaName: Scalars['String'];
  phone: Scalars['String'];
  owner?: Maybe<OwnerInput>;
};

export type OrganizationPage = Page & {
  __typename?: 'OrganizationPage';
  sorting?: Maybe<Array<SortField>>;
  paging: Paging;
  nodes: Array<Organization>;
};

export type Overview = {
  __typename?: 'Overview';
  id: Scalars['ID'];
  author: UserInfo;
  name: Scalars['String'];
  text: Scalars['String'];
};

export type OwnerInput = {
  email: Scalars['String'];
  fullName: Scalars['String'];
};

export type Page = {
  sorting?: Maybe<Array<SortField>>;
  paging: Paging;
};

export type Paging = {
  __typename?: 'Paging';
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PagingInput = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  id: Scalars['ID'];
  shop: Shop;
  default: Scalars['Boolean'];
  cardNumber: Scalars['String'];
  nameOnCard: Scalars['String'];
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  cvc: Scalars['String'];
  address: BillingAddress;
  author: Shop;
};

export type PaymentMethodInput = {
  shopId: Scalars['String'];
  defaultMethod: Scalars['Boolean'];
  cardNumber: Scalars['String'];
  nameOnCard: Scalars['String'];
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  cvc: Scalars['String'];
  address: BillingAddressInput;
};

export type PaymentSource = {
  type: PaymentSourceType;
  id?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  encrypted?: Maybe<EncryptedInput>;
  card?: Maybe<CreditCard>;
  persist?: Maybe<Scalars['Boolean']>;
};

export enum PaymentSourceType {
  Token = 'TOKEN',
  Card = 'CARD',
  StoredCard = 'STORED_CARD',
  ScutiCrypto = 'SCUTI_CRYPTO',
}

export type PersonalInfo = {
  __typename?: 'PersonalInfo';
  phone?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['Float']>;
  birthDate?: Maybe<Scalars['LocalDate']>;
};

export type PersonalInformationInput = {
  fullName: Scalars['String'];
  birthDate: Scalars['LocalDate'];
  gender: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
};

export enum Platform {
  Linux = 'LINUX',
  Pc = 'PC',
  Mac = 'MAC',
  Ios = 'IOS',
  Android = 'ANDROID',
  Web = 'WEB',
  Other = 'OTHER',
}

export type PlatformSettings = {
  __typename?: 'PlatformSettings';
  scutiExchangeRate: Scalars['Float'];
  isNewOfferDays: Scalars['Float'];
  isMiscOfferCategoryLimit: Scalars['Float'];
};

export type Preferences = {
  __typename?: 'Preferences';
  categories?: Maybe<Array<Scalars['String']>>;
};

export type PreferencesInput = {
  categories: Array<Scalars['String']>;
};

export type Price = {
  __typename?: 'Price';
  amount: Scalars['Float'];
  currency: Scalars['String'];
};

export type PriceInput = {
  amount: Scalars['Float'];
  currency: Scalars['String'];
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  reviewUrl?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  images?: Maybe<Array<Scalars['String']>>;
  defaultImage?: Maybe<Scalars['String']>;
  option1?: Maybe<Scalars['String']>;
  option2?: Maybe<Scalars['String']>;
  option3?: Maybe<Scalars['String']>;
  shop: ShopInfo;
  chargeTaxes: Scalars['Boolean'];
  productListings: Array<CampaignInfo>;
  variants: Array<ProductVariant>;
};

export type ProductInfo = {
  __typename?: 'ProductInfo';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  reviewUrl?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  images?: Maybe<Array<Scalars['String']>>;
  defaultImage?: Maybe<Scalars['String']>;
  option1?: Maybe<Scalars['String']>;
  option2?: Maybe<Scalars['String']>;
  option3?: Maybe<Scalars['String']>;
};

export type ProductInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  category: Scalars['String'];
  chargeTaxes: Scalars['Boolean'];
  tags?: Maybe<Array<Scalars['String']>>;
  images?: Maybe<Array<Scalars['String']>>;
  defaultImage?: Maybe<Scalars['String']>;
  reviewUrl?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  variants: Array<ProductVariantInput>;
  option1?: Maybe<Scalars['String']>;
  option2?: Maybe<Scalars['String']>;
  option3?: Maybe<Scalars['String']>;
  shopId: Scalars['String'];
};

export type ProductPage = Page & {
  __typename?: 'ProductPage';
  sorting?: Maybe<Array<SortField>>;
  paging: Paging;
  nodes: Array<Product>;
};

export type ProductVariant = {
  __typename?: 'ProductVariant';
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  price: Price;
  compareAt?: Maybe<Price>;
  sku: Scalars['String'];
  barcode?: Maybe<Scalars['String']>;
  weight?: Maybe<Weight>;
  inStock: Scalars['Float'];
  fulfillmentMethod: FulfillmentMethod;
  option1?: Maybe<Scalars['String']>;
  option2?: Maybe<Scalars['String']>;
  option3?: Maybe<Scalars['String']>;
  variantPosition?: Maybe<Scalars['Float']>;
};

export type ProductVariantInput = {
  id?: Maybe<Scalars['ID']>;
  ebridgeProductId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  price: PriceInput;
  compareAt?: Maybe<PriceInput>;
  sku: Scalars['String'];
  barcode?: Maybe<Scalars['String']>;
  weight?: Maybe<WeightInput>;
  inStock: Scalars['Float'];
  fulfillmentMethodId: Scalars['String'];
  option1?: Maybe<Scalars['String']>;
  option2?: Maybe<Scalars['String']>;
  option3?: Maybe<Scalars['String']>;
};

export enum PromotionType {
  HotPrice = 'HOT_PRICE',
  SpecialOffer = 'SPECIAL_OFFER',
}

export type Query = {
  __typename?: 'Query';
  /** [SCUTI_USER] - me/my profile */
  me: User;
  /** [SCUTI_USER] - my wallet */
  myWallet: Wallet;
  /** [SCUTI_USER?] - show platform settings */
  platformSettings: PlatformSettings;
  /** [DEBUG] [GAME_ADMIN,SHOP_ADMIN,GAME_OWNER,SHOP_OWNER,SCUTI_ADMIN] - print my access */
  myAccess: AccessInfo;
  /** [SHOP_OWNER,SCUTI_ADMIN,SHOP_ADMIN] - query for shops */
  shops: ShopPage;
  /** [SHOP_OWNER,SCUTI_ADMIN,SHOP_ADMIN] - get shop by id */
  shop?: Maybe<Shop>;
  /** [SHOP_OWNER,SCUTI_ADMIN,SHOP_ADMIN] - get shop brands */
  shopBrands: Array<ShopBrand>;
  /** [SCUTI_ADMIN,SHOP_ADMIN,SHOP_OWNER] - query for shop's products */
  shopProducts: ProductPage;
  /** [SCUTI_ADMIN,SHOP_ADMIN,SHOP_OWNER] - get product by id */
  product: Product;
  /** [SCUTI_USER,SCUTI_ADMIN,SHOP_ADMIN,GAME_ADMIN,SHOP_OWNER,GAME_OWNER,ORGANIZATION_OWNER] - get available product categories */
  productCategories: Array<Scalars['String']>;
  /** [SCUTI_ADMIN,GAME_OWNER,GAME_ADMIN,SHOP_ADMIN] - query for games */
  games: GamePage;
  /** [SCUTI_ADMIN,GAME_OWNER,GAME_ADMIN,GAME_SERVER] - get game by id */
  game: Game;
  /** [SCUTI_ADMIN,GAME_OWNER,GAME_ADMIN] - get game token by game id */
  gameToken?: Maybe<Token>;
  /** [SCUTI_USER?] - get game info by id */
  gameInfo?: Maybe<GameInfo>;
  shopOverviews: Array<Overview>;
  /** [SCUTI_ADMIN] - query for campaigns */
  campaigns: CampaignPage;
  /** [SCUTI_ADMIN] - query for shop campaigns by shop id */
  shopCampaigns: CampaignPage;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - get campaign by id */
  campaign: Campaign;
  /** [SCUTI_ADMIN] - query for today deals campaigns */
  todayDeals: Array<Campaign>;
  /** [SCUTI_ADMIN] - query for advertisements */
  advertisements: AdvertisementPage;
  /** [SCUTI_ADMIN] - query for brand advertisements by brand id */
  brandAdvertisements: AdvertisementPage;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - get advertisement by id */
  advertisement: Advertisement;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - get all bank accounts by organization id */
  organizationBankAccounts?: Maybe<Array<BankAccount>>;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - get assigned shop bank account or default organization bank account */
  shopBankAccount?: Maybe<BankAccount>;
  /** @deprecated subject to change */
  shopFulfillmentMethods: Array<FulfillmentMethod>;
  shopPaymentMethods: Array<PaymentMethod>;
  /** [SCUTI_USER?] - query for offers */
  offers: OfferPage;
  /** [SCUTI_USER?] - get offer by id */
  offer: Offer;
  /** [SCUTI_USER?]  - get overall offer statistics */
  offerStatistics: OfferStatistics;
  /** [SCUTI_USER] - my rewards */
  myRewards: RewardPage;
  /** [SCUTI_ADMIN,ORGANIZATION_OWNER,SHOP_OWNER,GAME_OWNER] - query for organizations */
  organizations: OrganizationPage;
  /** [SCUTI_ADMIN,ORGANIZATION_OWNER,SHOP_OWNER,GAME_OWNER] - get organization by id */
  organization: Organization;
  /** [SCUTI_ADMIN,SCUTI_USER,SCUTI_ADMIN,SHOP_ADMIN,GAME_ADMIN,SHOP_OWNER,GAME_OWNER,ORGANIZATION_OWNER] - query for invites */
  invites: InvitePage;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - query for shop invites */
  shopInvites: InvitePage;
  /** [SCUTI_ADMIN,GAME_OWNER,GAME_ADMIN] - query for game invites */
  gameInvites: InvitePage;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - query for shop orders by shop id */
  shopOrders: OrderPage;
  /** [SCUTI_USER] - query for my orders */
  myOrders: OrderPage;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - get order by id */
  order: Order;
  /** [SCUTI_USER] - get my oder by id */
  myOrder: Order;
  /** [SCUTI_USER] - get my order history by order id */
  myOrderHistory: Array<OrderHistory>;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - get order history by order id */
  orderHistory: Array<OrderHistory>;
  /** [SCUTI_USER] - get my cards */
  myCards: Array<UserCard>;
  /** [SCUTI_USER] - get my card details */
  myCardDetails: UserCardDetails;
  /** [SCUTI_USER] - get encryption key */
  encryptionKey: CheckoutEncryption;
};

export type QueryShopsArgs = {
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  filters?: Maybe<Array<FilterInput>>;
};

export type QueryShopArgs = {
  id: Scalars['String'];
};

export type QueryShopBrandsArgs = {
  id: Scalars['String'];
};

export type QueryShopProductsArgs = {
  filters?: Maybe<Array<FilterInput>>;
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  id: Scalars['ID'];
};

export type QueryProductArgs = {
  id: Scalars['String'];
};

export type QueryGamesArgs = {
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  filters?: Maybe<Array<FilterInput>>;
};

export type QueryGameArgs = {
  id: Scalars['String'];
};

export type QueryGameTokenArgs = {
  id: Scalars['String'];
};

export type QueryGameInfoArgs = {
  id: Scalars['String'];
};

export type QueryShopOverviewsArgs = {
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  id: Scalars['ID'];
};

export type QueryCampaignsArgs = {
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  filters?: Maybe<Array<FilterInput>>;
};

export type QueryShopCampaignsArgs = {
  filters?: Maybe<Array<FilterInput>>;
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  id: Scalars['ID'];
};

export type QueryCampaignArgs = {
  id: Scalars['String'];
};

export type QueryTodayDealsArgs = {
  shopId: Scalars['String'];
};

export type QueryAdvertisementsArgs = {
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  filters?: Maybe<Array<FilterInput>>;
};

export type QueryBrandAdvertisementsArgs = {
  filters?: Maybe<Array<FilterInput>>;
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  id: Scalars['ID'];
};

export type QueryAdvertisementArgs = {
  id: Scalars['String'];
};

export type QueryOrganizationBankAccountsArgs = {
  id: Scalars['String'];
};

export type QueryShopBankAccountArgs = {
  id: Scalars['String'];
};

export type QueryShopFulfillmentMethodsArgs = {
  id: Scalars['String'];
};

export type QueryShopPaymentMethodsArgs = {
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  id: Scalars['ID'];
};

export type QueryOffersArgs = {
  filters?: Maybe<Array<FilterInput>>;
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  seed?: Maybe<Scalars['Int']>;
};

export type QueryOfferArgs = {
  id: Scalars['String'];
};

export type QueryMyRewardsArgs = {
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  filters?: Maybe<Array<FilterInput>>;
};

export type QueryOrganizationsArgs = {
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  filters?: Maybe<Array<FilterInput>>;
};

export type QueryOrganizationArgs = {
  id: Scalars['String'];
};

export type QueryInvitesArgs = {
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  filters?: Maybe<Array<FilterInput>>;
};

export type QueryShopInvitesArgs = {
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  filters?: Maybe<Array<FilterInput>>;
  id: Scalars['String'];
};

export type QueryGameInvitesArgs = {
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  filters?: Maybe<Array<FilterInput>>;
  id: Scalars['String'];
};

export type QueryShopOrdersArgs = {
  filters?: Maybe<Array<FilterInput>>;
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  id: Scalars['ID'];
};

export type QueryMyOrdersArgs = {
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  filters?: Maybe<Array<FilterInput>>;
};

export type QueryOrderArgs = {
  id: Scalars['String'];
};

export type QueryMyOrderArgs = {
  id: Scalars['String'];
};

export type QueryMyOrderHistoryArgs = {
  id: Scalars['String'];
};

export type QueryOrderHistoryArgs = {
  id: Scalars['String'];
};

export type QueryMyCardDetailsArgs = {
  id: Scalars['String'];
};

export type Receipt = {
  __typename?: 'Receipt';
  id: Scalars['String'];
  user: UserInfo;
  currency: Scalars['String'];
  items: Array<ReceiptItem>;
  breakdown: ReceiptBreakdown;
};

export type ReceiptBreakdown = {
  __typename?: 'ReceiptBreakdown';
  total: Scalars['Float'];
  retail: Scalars['Float'];
  tax: Scalars['Float'];
  shipping: Scalars['Float'];
};

export type ReceiptItem = {
  __typename?: 'ReceiptItem';
  shop: ReceiptShop;
  products: Array<ReceiptProduct>;
  breakdown: ReceiptBreakdown;
};

export type ReceiptProduct = {
  __typename?: 'ReceiptProduct';
  variantId: Scalars['String'];
  offerId: Scalars['String'];
  productName: Scalars['String'];
  productDescription?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  price: Scalars['Float'];
  breakdown: ReceiptBreakdown;
  reward: ReceiptReward;
};

export type ReceiptReward = {
  __typename?: 'ReceiptReward';
  scutis?: Maybe<Scalars['Int']>;
};

export type ReceiptShop = {
  __typename?: 'ReceiptShop';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type Reward = {
  __typename?: 'Reward';
  id: Scalars['ID'];
  activated: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  type: Scalars['String'];
  scutiAccountType: Scalars['String'];
  scutis?: Maybe<Scalars['Float']>;
  user: UserInfo;
};

export type RewardPage = Page & {
  __typename?: 'RewardPage';
  sorting?: Maybe<Array<SortField>>;
  paging: Paging;
  nodes: Array<Reward>;
};

export enum Role {
  ScutiUser = 'SCUTI_USER',
  ScutiAdmin = 'SCUTI_ADMIN',
  ShopAdmin = 'SHOP_ADMIN',
  GameAdmin = 'GAME_ADMIN',
  Login_2Fa = 'LOGIN_2FA',
  VerifyEmail = 'VERIFY_EMAIL',
  AcceptSystemInvite = 'ACCEPT_SYSTEM_INVITE',
  AcceptEntityInvite = 'ACCEPT_ENTITY_INVITE',
  Refresh = 'REFRESH',
  OrganizationOwner = 'ORGANIZATION_OWNER',
  ShopOwner = 'SHOP_OWNER',
  GameOwner = 'GAME_OWNER',
  ChangePassword = 'CHANGE_PASSWORD',
  ChangePassword_2Fa = 'CHANGE_PASSWORD_2FA',
  GameServer = 'GAME_SERVER',
}

export type ScutiExchange = {
  __typename?: 'ScutiExchange';
  scutisSpent: Scalars['Float'];
  currencyEarned: Scalars['Float'];
  currencyName: Scalars['String'];
  id: Scalars['String'];
};

export type SdkInitMetric = {
  session: MetricSession;
  gameId: Scalars['String'];
};

export type ShippingInfo = {
  __typename?: 'ShippingInfo';
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  zipCode: Scalars['String'];
  country: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type ShippingInformationInput = {
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  zipCode: Scalars['String'];
  country: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type Shop = {
  __typename?: 'Shop';
  id: Scalars['ID'];
  name: Scalars['String'];
  thumbnail?: Maybe<Scalars['String']>;
  shopifyName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  owner: UserInfo;
  admins: Array<UserWithRoles>;
  analytics: ShopAnalytics;
  flatShippingRate: Scalars['Float'];
  freeShippingThreshold?: Maybe<Scalars['Float']>;
  returnPolicy: Scalars['String'];
};

export type ShopAccess = {
  __typename?: 'ShopAccess';
  id: Scalars['String'];
  name: Scalars['String'];
  role: Scalars['String'];
  owner: UserInfo;
};

export type ShopAgeAnalytics = {
  __typename?: 'ShopAgeAnalytics';
  revenue: Scalars['Float'];
  units: Scalars['Float'];
  avgPurchaseValue: Scalars['Float'];
  itemsPerCheckout: Scalars['Float'];
  age: Scalars['Float'];
};

export type ShopAnalytics = {
  __typename?: 'ShopAnalytics';
  revenue: Scalars['Float'];
  units: Scalars['Float'];
  avgPurchaseValue: Scalars['Float'];
  itemsPerCheckout: Scalars['Float'];
  campaignAnalytics: Array<ShopCampaignAnalytics>;
  ageAnalytics: Array<ShopAgeAnalytics>;
  genderAnalytics: Array<ShopGenderAnalytics>;
};

export type ShopBrand = {
  __typename?: 'ShopBrand';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ShopBrandInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ShopCampaignAnalytics = {
  __typename?: 'ShopCampaignAnalytics';
  revenue: Scalars['Float'];
  units: Scalars['Float'];
  avgPurchaseValue: Scalars['Float'];
  itemsPerCheckout: Scalars['Float'];
  campaignId: Scalars['ID'];
};

export type ShopGenderAnalytics = {
  __typename?: 'ShopGenderAnalytics';
  revenue: Scalars['Float'];
  units: Scalars['Float'];
  avgPurchaseValue: Scalars['Float'];
  itemsPerCheckout: Scalars['Float'];
  gender: Scalars['String'];
};

export type ShopInfo = {
  __typename?: 'ShopInfo';
  id: Scalars['ID'];
  name: Scalars['String'];
  thumbnail?: Maybe<Scalars['String']>;
  shopifyName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type ShopInput = {
  thumbnail?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  shopifyName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  flatShippingRate: Scalars['Float'];
  freeShippingThreshold?: Maybe<Scalars['Float']>;
  returnPolicy: Scalars['String'];
  organizationId?: Maybe<Scalars['String']>;
};

export type ShopPage = Page & {
  __typename?: 'ShopPage';
  sorting?: Maybe<Array<SortField>>;
  paging: Paging;
  nodes: Array<Shop>;
};

export type SocializationOfBrandMetric = {
  session: MetricSession;
  gameId: Scalars['String'];
  brandId: Scalars['String'];
  socialName: Scalars['String'];
};

export type SortField = {
  __typename?: 'SortField';
  name: Scalars['String'];
  dir: Scalars['String'];
};

export type SortFieldInput = {
  name: Scalars['String'];
  dir: Scalars['String'];
};

export type SyncEbridgeInput = {
  id: Scalars['ID'];
};

export type SyncEbridgeResult = {
  __typename?: 'SyncEbridgeResult';
  date: Scalars['DateTime'];
};

export type TimeSpentPerusingMetric = {
  session: MetricSession;
  gameId: Scalars['String'];
  /** duration in seconds */
  duration: Scalars['Int'];
};

export type ToggleCampaignInput = {
  id: Scalars['ID'];
  isPaused: Scalars['Boolean'];
};

export type Token = {
  __typename?: 'Token';
  id: Scalars['ID'];
  token: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export type UpdateAdvertisementInput = {
  name?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  cpa?: Maybe<Scalars['Float']>;
  appDownload?: Maybe<AdvertisementAppDownloadInput>;
  video?: Maybe<AdvertisementVideoInput>;
  leadGen?: Maybe<AdvertisementLeadGenInput>;
  coupon?: Maybe<AdvertisementCouponInput>;
  noAction?: Maybe<AdvertisementNoActionInput>;
  internal?: Maybe<AdvertisementInternalInput>;
  id: Scalars['ID'];
  type: AdvertisementType;
  brandId: Scalars['String'];
};

export type UpdateBankAccountInput = {
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  zipCode: Scalars['String'];
  country: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  bankName: Scalars['String'];
  routingNumber: Scalars['String'];
  accountNumber: Scalars['String'];
  isDefault: Scalars['Boolean'];
  id: Scalars['ID'];
  organizationId: Scalars['String'];
};

export type UpdateCampaignInput = {
  name: Scalars['String'];
  category?: Maybe<Scalars['String']>;
  product?: Maybe<CampaignProductInput>;
  productListingId?: Maybe<Scalars['String']>;
  media?: Maybe<CampaignMediaInput>;
  video?: Maybe<CampaignVideoInput>;
  demographic: CampaignDemographicInput;
  duration: CampaignDurationInput;
  budget?: Maybe<CampaignBudgetInput>;
  game?: Maybe<CampaignGameInput>;
  location: CampaignLocationInput;
  reward?: Maybe<CampaignRewardInput>;
  extras: CampaignExtrasInput;
  shopBrandId?: Maybe<Scalars['ID']>;
  internalUrl?: Maybe<Scalars['String']>;
  appDownloadIds?: Maybe<CampaignAppDownloadIdsInput>;
  type: CampaignType;
  shopId: Scalars['String'];
  id: Scalars['ID'];
};

export type UpdateFulfillmentMethodInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type UpdateGameInput = {
  name: Scalars['String'];
  category: Scalars['String'];
  tags?: Maybe<Array<Scalars['String']>>;
  thumbnail?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
  currency?: Maybe<CurrencyInput>;
  ageFrom: Scalars['Int'];
  ageTo: Scalars['Int'];
  contentCategory: ContentCategory;
  genderPercent?: Maybe<GenderPercentInput>;
  id: Scalars['ID'];
};

export type UpdateOrganizationInput = {
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  zipCode: Scalars['String'];
  country: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  website?: Maybe<Scalars['String']>;
  category: Scalars['String'];
  identificationNumber: Scalars['String'];
  dbaName: Scalars['String'];
  phone: Scalars['String'];
  id: Scalars['ID'];
};

export type UpdatePaymentMethodInput = {
  id: Scalars['ID'];
  defaultMethod?: Maybe<Scalars['Boolean']>;
  cardNumber?: Maybe<Scalars['String']>;
  nameOnCard?: Maybe<Scalars['String']>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  cvc?: Maybe<Scalars['String']>;
  address?: Maybe<BillingAddressInput>;
};

export type UpdateProductInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  category: Scalars['String'];
  chargeTaxes: Scalars['Boolean'];
  tags?: Maybe<Array<Scalars['String']>>;
  images?: Maybe<Array<Scalars['String']>>;
  defaultImage?: Maybe<Scalars['String']>;
  reviewUrl?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  variants: Array<ProductVariantInput>;
  option1?: Maybe<Scalars['String']>;
  option2?: Maybe<Scalars['String']>;
  option3?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type UpdateShopInput = {
  thumbnail?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  shopifyName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  flatShippingRate: Scalars['Float'];
  freeShippingThreshold?: Maybe<Scalars['Float']>;
  returnPolicy: Scalars['String'];
  id: Scalars['ID'];
};

export type UrlOfferMetricBase = {
  session: MetricSession;
  url: Scalars['String'];
  offerId: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  email: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  roles: Array<Role>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  phone?: Maybe<Scalars['String']>;
  emailVerified: Scalars['Boolean'];
  phoneVerified: Scalars['Boolean'];
  twoFactorEnabled: Scalars['Boolean'];
  personalInfo?: Maybe<PersonalInfo>;
  preferences?: Maybe<Preferences>;
  shippingInfo?: Maybe<ShippingInfo>;
};

export type UserCard = {
  __typename?: 'UserCard';
  id: Scalars['String'];
  last4: Scalars['String'];
  expiryMonth: Scalars['Float'];
  expiryYear: Scalars['Float'];
  scheme: Scalars['String'];
  isDefault: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
};

export type UserCardDetails = {
  __typename?: 'UserCardDetails';
  id: Scalars['String'];
  last4: Scalars['String'];
  expiryMonth: Scalars['Float'];
  expiryYear: Scalars['Float'];
  scheme: Scalars['String'];
  isDefault: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  billingAddress: Address;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  id: Scalars['ID'];
  email: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
};

export type UserRoleInput = {
  email: Scalars['String'];
  roles: Array<Role>;
};

export type UserWithRoles = {
  __typename?: 'UserWithRoles';
  id: Scalars['ID'];
  email: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  roles: Array<Role>;
};

export type Wallet = {
  __typename?: 'Wallet';
  promotional: Scalars['Float'];
  purchase: Scalars['Float'];
};

export type Weight = {
  __typename?: 'Weight';
  amount: Scalars['Float'];
  unit: WeightUnit;
};

export type WeightInput = {
  amount: Scalars['Float'];
  unit: WeightUnit;
};

export enum WeightUnit {
  Lb = 'LB',
  Kg = 'KG',
  G = 'G',
  FlOz = 'FL_OZ',
}

export const AdvertisementFull = gql`
  fragment AdvertisementFull on Advertisement {
    id
    name
    brandId
    cpa
    category
    type
    appDownload {
      appleId
      androidId
      pcLink
    }
    coupon {
      couponLabel
      couponDescription
      couponImages
    }
    internal {
      internalLabel
      internalHtml5Url
      internalUnityUrl
    }
    video {
      videoUrl
    }
    noAction {
      noActionLabel
    }
    leadGen {
      affiliateUrl
    }
  }
`;
export const AdvertisementItem = gql`
  fragment AdvertisementItem on Advertisement {
    id
    name
    brandId
    cpa
    category
    type
    appDownload {
      appleId
      androidId
      pcLink
    }
    coupon {
      couponLabel
      couponDescription
      couponImages
    }
    internal {
      internalLabel
      internalHtml5Url
      internalUnityUrl
    }
    video {
      videoUrl
    }
    noAction {
      noActionLabel
    }
    leadGen {
      affiliateUrl
    }
  }
`;
export const CampaignFull = gql`
  fragment CampaignFull on Campaign {
    id
    name
    category
    shop {
      id
      name
    }
    type
    status
    product {
      product {
        id
        name
      }
      promotion {
        promotionType
        offerType
        offerCopy
        discount
      }
    }
    media {
      banner
      vertical
      tile
    }
    video {
      videoUrl
    }
    demographic {
      gender
      minAge
      maxAge
      contentCategory
    }
    duration {
      start
      end
      inventory
      runUntilStockZero
    }
    productListingId
    budget {
      option
      maxSpend
      maxDailySpend
      limitOfImpressions
    }
    game {
      games {
        id
        name
      }
      gameGenres
    }
    location {
      country
      states
    }
    reward {
      scutiPercentage
    }
    extras {
      moreExposure
    }
    appDownloadIds {
      appleId
      androidId
      pcLink
    }
  }
`;
export const CampaignItem = gql`
  fragment CampaignItem on Campaign {
    id
    name
    category
    type
    status
    shop {
      id
    }
    product {
      product {
        id
        name
        images
      }
    }
    demographic {
      gender
      minAge
      maxAge
    }
    budget {
      maxSpend
      maxDailySpend
      limitOfImpressions
    }
    duration {
      start
      end
      inventory
      runUntilStockZero
    }
    budget {
      maxSpend
      maxDailySpend
      limitOfImpressions
    }
    statistics {
      totalSpend
      totalRevenue
      roi
    }
  }
`;
export const FullGame = gql`
  fragment FullGame on Game {
    id
    name
    category
    tags
    thumbnail
    banner
    currency {
      name
      thumbnail
      exchangeRate
      scutiExchangeRate
    }
    contentCategory
    ageFrom
    ageTo
    genderPercent {
      male
      female
    }
    admins {
      fullName
      email
      roles
    }
  }
`;
export const GameInvite = gql`
  fragment GameInvite on Invite {
    id
    createdAt
    updatedAt
    email
    roles
    entityId
    entityType
    accepted
    hide
  }
`;
export const GameDetails = gql`
  fragment GameDetails on Game {
    id
    name
    category
    tags
    banner
    thumbnail
    ageFrom
    ageTo
    contentCategory
    genderPercent {
      male
      female
    }
    currency {
      name
      thumbnail
      exchangeRate
    }
    admins {
      id
      email
      roles
      fullName
    }
  }
`;
export const GameItem = gql`
  fragment GameItem on Game {
    id
    name
    tags
    thumbnail
    category
    contentCategory
    admins {
      fullName
    }
  }
`;
export const OrderListItem = gql`
  fragment OrderListItem on Order {
    id
    createdAt
    amount
    user {
      id
      fullName
      email
    }
    game {
      name
    }
    status
    items {
      quantity
    }
  }
`;
export const OrderProduct = gql`
  fragment OrderProduct on OrderProductSnapshot {
    id
    name
    description
    category
    variant {
      id
      image
      price {
        currency
        amount
      }
      compareAt {
        currency
        amount
      }
      sku
      barcode
      weight {
        amount
        unit
      }
      inStock
    }
  }
`;
export const Order = gql`
  fragment Order on Order {
    id
    createdAt
    user {
      id
      email
      fullName
    }
    amount
    shippingInformation {
      phone
      address1
      address2
      city
      zipCode
      country
      state
    }
    items {
      id
      fulfillmentMethod {
        name
        type
      }
      amount
      quantity
      product {
        ...OrderProduct
      }
    }
    status
  }
  ${OrderProduct}
`;
export const OrganizationFull = gql`
  fragment OrganizationFull on Organization {
    id
    address1
    address2
    city
    zipCode
    country
    state
    name
    website
    category
    phone
    identificationNumber
    dbaName
    owner {
      id
      fullName
      email
    }
  }
`;
export const OrganizationItem = gql`
  fragment OrganizationItem on Organization {
    id
    name
    website
    category
    owner {
      fullName
    }
  }
`;
export const ProductListingFull = gql`
  fragment ProductListingFull on Campaign {
    id
    name
    category
    shop {
      id
      name
    }
    status
    product {
      product {
        id
        name
      }
      promotion {
        promotionType
        offerType
        offerCopy
        discount
      }
    }
    shopBrand {
      id
      name
    }
    demographic {
      gender
      minAge
      maxAge
      contentCategory
    }
    duration {
      start
      end
      inventory
      runUntilStockZero
    }
    location {
      country
      states
    }
    reward {
      scutiPercentage
    }
    extras {
      moreExposure
    }
  }
`;
export const ProductListingItem = gql`
  fragment ProductListingItem on Campaign {
    id
    name
    category
    type
    status
    shop {
      id
    }
    product {
      product {
        id
        name
        images
      }
    }
    duration {
      start
      end
      inventory
      runUntilStockZero
    }
    budget {
      maxSpend
      maxDailySpend
      limitOfImpressions
    }
    statistics {
      totalSpend
      totalRevenue
      roi
    }
  }
`;
export const FullProduct = gql`
  fragment FullProduct on Product {
    id
    name
    description
    defaultImage
    reviewUrl
    category
    tags
    images
    option1
    option2
    option3
    shop {
      id
      shopifyName
    }
    chargeTaxes
    variants {
      id
      image
      price {
        amount
        currency
      }
      compareAt {
        amount
        currency
      }
      sku
      barcode
      weight {
        amount
        unit
      }
      inStock
      fulfillmentMethod {
        id
        name
        type
      }
      option1
      option2
      option3
    }
  }
`;
export const ShopFull = gql`
  fragment ShopFull on Shop {
    id
    name
    description
    shopifyName
    flatShippingRate
    freeShippingThreshold
    returnPolicy
    thumbnail
    admins {
      fullName
      email
      roles
    }
  }
`;
export const ShopInvite = gql`
  fragment ShopInvite on Invite {
    id
    createdAt
    updatedAt
    email
    roles
    entityId
    entityType
    accepted
    hide
  }
`;
export const ShopBrand = gql`
  fragment ShopBrand on ShopBrand {
    id
    name
  }
`;
export const ShopItemFg = gql`
  fragment ShopItemFG on Shop {
    id
    name
    description
    shopifyName
    thumbnail
    admins {
      id
      email
      roles
      fullName
    }
  }
`;
export const ProductCategories = gql`
  query productCategories {
    productCategories
  }
`;
export const UserGameItems = gql`
  query UserGameItems {
    games(paging: { offset: 0, limit: 50 }) {
      nodes {
        id
        name
      }
    }
  }
`;
export const UserShopItems = gql`
  query UserShopItems {
    shops(paging: { offset: 0, limit: 50 }) {
      nodes {
        id
        name
        shopifyName
      }
    }
  }
`;
export const Advertisement = gql`
  query Advertisement($id: String!) {
    advertisement(id: $id) {
      ...AdvertisementFull
    }
  }
  ${AdvertisementFull}
`;
export const CreateAdvertisement = gql`
  mutation createAdvertisement($input: NewAdvertisementInput!) {
    createAdvertisement(input: $input) {
      ...AdvertisementFull
    }
  }
  ${AdvertisementFull}
`;
export const UpdateAdvertisement = gql`
  mutation updateAdvertisement($input: UpdateAdvertisementInput!) {
    updateAdvertisement(input: $input) {
      ...AdvertisementFull
    }
  }
  ${AdvertisementFull}
`;
export const Advertisements = gql`
  query Advertisements($paging: PagingInput, $sorting: [SortFieldInput!], $filters: [FilterInput!]) {
    advertisements(paging: $paging, sorting: $sorting, filters: $filters) {
      sorting {
        name
        dir
      }
      paging {
        offset
        limit
        totalCount
      }
      nodes {
        ...AdvertisementItem
      }
    }
  }
  ${AdvertisementItem}
`;
export const Campaign = gql`
  query Campaign($id: String!) {
    campaign(id: $id) {
      ...CampaignFull
    }
  }
  ${CampaignFull}
`;
export const CreateCampaign = gql`
  mutation createCampaign($input: NewCampaignInput!) {
    createCampaign(input: $input) {
      ...CampaignFull
    }
  }
  ${CampaignFull}
`;
export const UpdateCampaign = gql`
  mutation updateCampaign($input: UpdateCampaignInput!) {
    updateCampaign(input: $input) {
      ...CampaignFull
    }
  }
  ${CampaignFull}
`;
export const CampaignProducts = gql`
  query CampaignProducts($id: ID!, $filters: [FilterInput!]) {
    shopCampaigns(id: $id, filters: $filters) {
      nodes {
        id
        product {
          product {
            id
            name
          }
        }
      }
    }
  }
`;
export const ToggleCampaign = gql`
  mutation ToggleCampaign($input: ToggleCampaignInput!) {
    toggleCampaign(input: $input) {
      id
    }
  }
`;
export const Campaigns = gql`
  query Campaigns($paging: PagingInput, $sorting: [SortFieldInput!], $filters: [FilterInput!], $id: ID!) {
    shopCampaigns(id: $id, paging: $paging, sorting: $sorting, filters: $filters) {
      sorting {
        name
        dir
      }
      paging {
        offset
        limit
        totalCount
      }
      nodes {
        ...CampaignItem
      }
    }
  }
  ${CampaignItem}
`;
export const Game = gql`
  query Game($id: String!) {
    game(id: $id) {
      ...FullGame
    }
  }
  ${FullGame}
`;
export const UpdateGame = gql`
  mutation UpdateGame($input: UpdateGameInput!) {
    updateGame(input: $input) {
      ...FullGame
    }
  }
  ${FullGame}
`;
export const CreateGame = gql`
  mutation CreateGame($input: GameInput!) {
    createGame(input: $input) {
      ...FullGame
    }
  }
  ${FullGame}
`;
export const GameInvites = gql`
  query GameInvites($id: String!, $paging: PagingInput, $sorting: [SortFieldInput!], $filters: [FilterInput!]) {
    gameInvites(id: $id, paging: $paging, sorting: $sorting, filters: $filters) {
      paging {
        offset
        limit
        totalCount
      }
      nodes {
        ...GameInvite
      }
    }
  }
  ${GameInvite}
`;
export const InviteGameUser = gql`
  mutation InviteGameUser($id: String!, $user: UserRoleInput!) {
    inviteToGame(id: $id, user: $user)
  }
`;
export const RemoveFromGame = gql`
  mutation RemoveFromGame($email: String!, $gameId: String!) {
    removeFromGame(email: $email, gameId: $gameId)
  }
`;
export const ResendInvite = gql`
  mutation ResendInvite($email: String!) {
    resendInviteMail(email: $email)
  }
`;
export const GameOverview = gql`
  query GameOverview($id: String!) {
    game(id: $id) {
      ...GameDetails
    }
  }
  ${GameDetails}
`;
export const GameToken = gql`
  query GameToken($id: String!) {
    gameToken(id: $id) {
      id
      token
    }
  }
`;
export const CreateGameToken = gql`
  mutation CreateGameToken($id: String!) {
    createGameToken(id: $id) {
      id
      token
    }
  }
`;
export const GameItems = gql`
  query GameItems($paging: PagingInput, $filters: [FilterInput!], $sorting: [SortFieldInput!]) {
    games(paging: $paging, filters: $filters, sorting: $sorting) {
      paging {
        offset
        limit
        totalCount
      }
      nodes {
        ...GameItem
      }
    }
  }
  ${GameItem}
`;
export const DeleteGame = gql`
  mutation DeleteGame($id: String!) {
    deleteGame(id: $id)
  }
`;
export const ShopOrder = gql`
  query ShopOrder($id: String!) {
    order(id: $id) {
      ...Order
    }
  }
  ${Order}
`;
export const Orders = gql`
  query Orders($filters: [FilterInput!], $sorting: [SortFieldInput!], $paging: PagingInput, $id: ID!) {
    shopOrders(filters: $filters, sorting: $sorting, paging: $paging, id: $id) {
      paging {
        offset
        limit
        totalCount
      }
      nodes {
        ...OrderListItem
      }
    }
  }
  ${OrderListItem}
`;
export const SetOrderStatus = gql`
  mutation SetOrderStatus($status: OrderStatus!, $id: String!) {
    setOrderStatus(status: $status, id: $id) {
      id
      status
    }
  }
`;
export const Organization = gql`
  query Organization($id: String!) {
    organization(id: $id) {
      ...OrganizationFull
    }
  }
  ${OrganizationFull}
`;
export const UpdateOrganization = gql`
  mutation UpdateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      ...OrganizationFull
    }
  }
  ${OrganizationFull}
`;
export const CreateOrganization = gql`
  mutation CreateOrganization($input: OrganizationInput!) {
    createOrganization(input: $input) {
      ...OrganizationFull
    }
  }
  ${OrganizationFull}
`;
export const OrganizationShops = gql`
  query OrganizationShops($id: String!) {
    shops(
      paging: { offset: 0, limit: 50 }
      sorting: []
      filters: [{ name: "organizationId", operator: EQ, value: [$id] }]
    ) {
      nodes {
        id
        name
        description
        shopifyName
        admins {
          fullName
        }
      }
    }
  }
`;
export const OrganizationGames = gql`
  query OrganizationGames($id: String!) {
    games(
      paging: { offset: 0, limit: 50 }
      sorting: []
      filters: [{ name: "organizationId", operator: EQ, value: [$id] }]
    ) {
      nodes {
        id
        name
        thumbnail
        category
        owner {
          email
        }
        admins {
          fullName
        }
      }
    }
  }
`;
export const Organizations = gql`
  query Organizations($paging: PagingInput, $sorting: [SortFieldInput!], $filters: [FilterInput!]) {
    organizations(paging: $paging, sorting: $sorting, filters: $filters) {
      sorting {
        name
        dir
      }
      paging {
        offset
        limit
        totalCount
      }
      nodes {
        ...OrganizationItem
      }
    }
  }
  ${OrganizationItem}
`;
export const ProductListing = gql`
  query ProductListing($id: String!) {
    campaign(id: $id) {
      ...ProductListingFull
    }
  }
  ${ProductListingFull}
`;
export const CreateProductListing = gql`
  mutation createProductListing($input: NewCampaignInput!) {
    createCampaign(input: $input) {
      ...ProductListingFull
    }
  }
  ${ProductListingFull}
`;
export const UpdateProductListing = gql`
  mutation updateProductListing($input: UpdateCampaignInput!) {
    updateCampaign(input: $input) {
      ...ProductListingFull
    }
  }
  ${ProductListingFull}
`;
export const ListingShops = gql`
  query ListingShops {
    shops {
      nodes {
        id
        name
      }
    }
  }
`;
export const ListingProducts = gql`
  query ListingProducts($id: ID!, $filters: [FilterInput!]) {
    shopProducts(id: $id, filters: $filters) {
      nodes {
        id
        name
      }
    }
  }
`;
export const ListingBrands = gql`
  query ListingBrands($id: String!) {
    shopBrands(id: $id) {
      id
      name
    }
  }
`;
export const ToggleListing = gql`
  mutation ToggleListing($input: ToggleCampaignInput!) {
    toggleCampaign(input: $input) {
      id
    }
  }
`;
export const ListingOptions = gql`
  query ListingOptions($filters: [FilterInput!], $paging: PagingInput, $id: ID!) {
    shopCampaigns(filters: $filters, paging: $paging, id: $id) {
      nodes {
        id
        name
        product {
          product {
            id
            name
          }
        }
        type
      }
    }
  }
`;
export const TodayDeals = gql`
  query TodayDeals($shopId: String!) {
    todayDeals(shopId: $shopId) {
      id
      name
      type
      product {
        product {
          id
          name
        }
      }
    }
  }
`;
export const SetTodayDeals = gql`
  mutation SetTodayDeals($ids: [String!]!, $shopId: String!) {
    setTodayDeals(ids: $ids, shopId: $shopId) {
      id
    }
  }
`;
export const ProductListings = gql`
  query ProductListings($paging: PagingInput, $sorting: [SortFieldInput!], $filters: [FilterInput!], $id: ID!) {
    shopCampaigns(id: $id, paging: $paging, sorting: $sorting, filters: $filters) {
      sorting {
        name
        dir
      }
      paging {
        offset
        limit
        totalCount
      }
      nodes {
        ...ProductListingItem
      }
    }
  }
  ${ProductListingItem}
`;
export const DeleteProductListing = gql`
  mutation deleteProductListing($id: String!) {
    deleteCampaign(id: $id)
  }
`;
export const Product = gql`
  query Product($id: String!) {
    product(id: $id) {
      ...FullProduct
    }
  }
  ${FullProduct}
`;
export const UpdateProduct = gql`
  mutation UpdateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      ...FullProduct
    }
  }
  ${FullProduct}
`;
export const CreateProduct = gql`
  mutation CreateProduct($input: ProductInput!) {
    createProduct(input: $input) {
      ...FullProduct
    }
  }
  ${FullProduct}
`;
export const FulfillmentMethods = gql`
  query FulfillmentMethods($id: String!) {
    shopFulfillmentMethods(id: $id) {
      id
      name
      type
    }
  }
`;
export const ProductOverview = gql`
  query ProductOverview($id: String!) {
    product(id: $id) {
      id
      name
      description
      defaultImage
      reviewUrl
      category
      tags
      images
      option1
      option2
      option3
      shop {
        id
        shopifyName
      }
      chargeTaxes
      variants {
        id
        image
        price {
          amount
          currency
        }
        compareAt {
          amount
          currency
        }
        sku
        barcode
        weight {
          amount
          unit
        }
        inStock
        fulfillmentMethod {
          id
          name
          type
        }
        option1
        option2
        option3
      }
    }
  }
`;
export const Products = gql`
  query Products($filters: [FilterInput!], $paging: PagingInput, $sorting: [SortFieldInput!], $id: ID!) {
    shopProducts(filters: $filters, paging: $paging, sorting: $sorting, id: $id) {
      sorting {
        name
        dir
      }
      paging {
        offset
        limit
        totalCount
      }
      nodes {
        id
        name
        tags
        images
        shop {
          id
          shopifyName
        }
        variants {
          id
          image
          price {
            amount
            currency
          }
          inStock
        }
        productListings {
          id
          status
          durationStartDate
          durationEndDate
        }
      }
    }
  }
`;
export const SyncEbridge = gql`
  mutation SyncEbridge($input: SyncEbridgeInput!) {
    syncEbridge(input: $input) {
      date
    }
  }
`;
export const Shop = gql`
  query Shop($id: String!) {
    shop(id: $id) {
      ...ShopFull
    }
  }
  ${ShopFull}
`;
export const UpdateShop = gql`
  mutation UpdateShop($input: UpdateShopInput!) {
    updateShop(input: $input) {
      id
    }
  }
`;
export const CreateShop = gql`
  mutation CreateShop($input: ShopInput!) {
    createShop(input: $input) {
      id
    }
  }
`;
export const ShopInvites = gql`
  query ShopInvites($id: String!, $paging: PagingInput, $sorting: [SortFieldInput!], $filters: [FilterInput!]) {
    shopInvites(id: $id, paging: $paging, sorting: $sorting, filters: $filters) {
      paging {
        offset
        limit
        totalCount
      }
      nodes {
        ...ShopInvite
      }
    }
  }
  ${ShopInvite}
`;
export const InviteShopUser = gql`
  mutation InviteShopUser($id: String!, $user: UserRoleInput!) {
    inviteToShop(id: $id, user: $user)
  }
`;
export const RemoveFromShop = gql`
  mutation RemoveFromShop($email: String!, $shopId: String!) {
    removeFromShop(email: $email, shopId: $shopId)
  }
`;
export const ShopBrands = gql`
  query ShopBrands($id: String!) {
    shopBrands(id: $id) {
      ...ShopBrand
    }
  }
  ${ShopBrand}
`;
export const EditShopBrands = gql`
  mutation EditShopBrands($input: EditShopBrandsInput!) {
    editShopBrands(input: $input) {
      ...ShopBrand
    }
  }
  ${ShopBrand}
`;
export const ShopItems = gql`
  query ShopItems($paging: PagingInput, $filters: [FilterInput!], $sorting: [SortFieldInput!]) {
    shops(paging: $paging, filters: $filters, sorting: $sorting) {
      paging {
        offset
        limit
        totalCount
      }
      sorting {
        name
        dir
      }
      nodes {
        ...ShopItemFG
      }
    }
  }
  ${ShopItemFg}
`;
export const DeleteShop = gql`
  mutation deleteShop($id: String!) {
    deleteShop(id: $id)
  }
`;
