import { CampaignBudgetOption, CampaignType } from 'types/__generated__/types';
import { FormValidationSchema, transformedNumber } from 'utils/form-utils';
import { uploadImage } from 'utils/upload-image.utils';
import * as Yup from 'yup';
import { CampaignForm } from './CampaignForm.utils';

export const appDownloadCampaignFormDefaults = (): CampaignForm => ({
  name: '',
  category: null,
  appDownloadIds: {
    androidId: '',
    appleId: null,
    pcLink: '',
  },
  reward: {
    scutiPercentage: 0,
  },
  type: CampaignType.AppDownload,
  budget: {
    option: CampaignBudgetOption.Normal,
    maxSpend: 0,
    maxDailySpend: 0,
    limitOfImpressions: 0,
  },
  demographic: {
    gender: null,
    minAge: 0,
    maxAge: 100,
  },
  duration: {
    start: null,
    end: null,
    inventory: null,
    runUntilStockZero: null,
  },
  extras: { moreExposure: false },
  media: {
    banner: null,
    tile: null,
    vertical: null,
  },
});

const mapMedia = async (media: CampaignForm['media']) => {
  if (!media) return media;
  const { vertical, tile, banner } = media;
  return {
    vertical:
      vertical && typeof vertical === 'object'
        ? (await uploadImage(vertical, { height: 90, width: 728 })).url
        : vertical,
    tile: tile && typeof tile === 'object' ? (await uploadImage(tile, { height: 250, width: 300 })).url : tile,
    // @ts-ignore
    banner:
      banner && typeof banner === 'object' ? (await uploadImage(banner, { height: 600, width: 300 })).url : banner,
  };
};

export const mapAppDownloadCampaignToInput = async ({ media, ...campaign }: CampaignForm, shopId: string) => {
  return {
    ...campaign,
    media: media && (await mapMedia(media)),
    product: null,
    shopId,
    game: {},
    location: {},
  };
};

export const validationSchema = (): FormValidationSchema<CampaignForm> => {
  return Yup.object().shape({
    type: Yup.mixed().oneOf([CampaignType.AppDownload]),
    name: Yup.string()
      .min(3, 'Name should be at least 3 characters.')
      .max(25, 'Name can be 25 characters or less.')
      .required('Name is required!'),
    appDownloadIds: Yup.object().shape({
      androidId: Yup.string().nullable(),
      appleId: Yup.number().nullable(),
      pcLink: Yup.string().matches(/[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:/?#[\]@!\\$&'\\(\\)\\*\\+,;=.]+$/, {
        message: 'PC link is invalid.',
        excludeEmptyString: true,
      }),
    }),
    reward: Yup.object().shape({
      scutiPercentage: Yup.number()
        .min(0)
        .max(100),
    }),
    demographic: Yup.object().shape({
      gender: Yup.string().nullable(),
      minAge: Yup.number()
        .min(0)
        .max(100)
        .required(),
      maxAge: Yup.number()
        .min(0)
        .max(100)
        .required(),
    }),
    duration: Yup.object().shape({
      start: Yup.date().nullable(),
      end: Yup.date().nullable(),
      runUntilStockZero: Yup.boolean().nullable(),
      inventory: Yup.number().nullable(),
    }),
    location: Yup.object()
      .shape({
        country: Yup.string().nullable(),
        states: Yup.array()
          .of(Yup.string())
          .nullable(),
      })
      .nullable(),
    extras: Yup.object().shape({
      moreExposure: Yup.boolean(),
    }),
    budget: Yup.object().shape({
      option: Yup.mixed()
        .oneOf(Object.values(CampaignBudgetOption))
        .required(),
      maxSpend: transformedNumber.moreThan(0, 'Cannot be 0 or less').required('Max Spend is required!'),
      maxDailySpend: transformedNumber.moreThan(0, 'Cannot be 0 or less').required('Daily Max Spend is required!'),
      limitOfImpressions: transformedNumber.min(6, 'Cannot be less then 6').required('Daily Max Spend is required!'),
    }),
  });
};
