import React from 'react';
import { Switch } from 'react-router-dom';
import { advertisementRoutes } from 'routing';
import { PrivateRoute } from 'components/PrivateRoute';
import { AdvertisementsListPage } from './AdvertisementsListPage';
import { AdvertisementEditPage } from './AdvertisementEditPage';

export const AdvertisementsRoutes: React.FC = () => {
  return (
    <Switch>
      <PrivateRoute exact path={advertisementRoutes.ADVERTISEMENTS} component={AdvertisementsListPage} />
      <PrivateRoute exact path={advertisementRoutes.ADVERTISEMENT_NEW()} component={AdvertisementEditPage} />
      <PrivateRoute exact path={advertisementRoutes.ADVERTISEMENT_EDIT()} component={AdvertisementEditPage} />
    </Switch>
  );
};
