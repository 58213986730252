import React from 'react';
import { FormikProps } from 'formik';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { ScutiSelect } from 'components/material/ScutiSelect';
import { ScutiInput } from 'components/material/ScutiInput';
import { AdvertisementForm } from './AdvertisementForm.utils';
import { useProductCategories } from 'hooks/useProductCategories';

export const VideoAdvertisementVideo: React.FC<FormikProps<AdvertisementForm>> = props => {
  const { values, touched, errors, handleBlur, handleChange } = props;

  const productCategoriesOptions = useProductCategories();
  return (
    <Card>
      <CardHeader title="Video" />
      <CardContent>
        <Box>
          <ScutiInput
            title="Video URL *"
            name="video.videoUrl"
            placeholder="http://"
            value={values.video?.videoUrl || ''}
            // @ts-ignore
            error={touched.video?.videoUrl && errors['video.videoUrl']}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
        </Box>
        <Box mt={2}>
          <ScutiSelect
            name="category"
            title="Category *"
            placeholder="Select category"
            value={values.category}
            error={touched.category && errors.category}
            onChange={handleChange}
            onBlur={handleBlur}
            options={productCategoriesOptions}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
