import React, { ChangeEventHandler, useCallback } from 'react';
import { Box, Card, CardContent, CardHeader, Grid, TextField } from '@material-ui/core';
import { OrderFragment, useSetNoteOrderMutation, useSetOrderStatusMutation } from '../__generated__/orders.hooks';
import { addDecimals } from 'utils/string.utils';
import noPicUrl from 'assets/svgs/no-pic-sm.svg';
import moment from 'moment';
import { ScutiInputVendorOrder } from '../../../components/material/ScutiInputVendorOrder';
import { ScutiInputNoteOrder } from '../../../components/material/ScutiInputNoteOrder';
import { ScutiSelect } from '../../../components/material/ScutiSelect';
import { FULFILLMENT_SELECT_OPTIONS } from '../../../types/select-types';
import { OrderStatus } from '../../../types/__generated__/types';

interface Props {
  order: OrderFragment;
  onCancelGroup: () => void;
  addTrackingNumber: (trackingNumber: string) => void;
}

export const OrderDetails: React.FC<Props> = ({ order, addTrackingNumber }) => {
  const [trackingNumber, setTrackingNumber] = React.useState('');
  const useSetOrderStatus = useSetOrderStatusMutation();
  const onTrackingNumberChange: ChangeEventHandler<{ value: string }> = e => setTrackingNumber(e.target.value);
  const onAddTrackingNumber = () => addTrackingNumber(trackingNumber);
  const shoppingMethod = order.items[0]?.fulfillmentMethod.name;

  const handleFulfillmentChange = useCallback(
    async (orderId: string, fulfillment: OrderStatus) => {
      await useSetOrderStatus.mutateAsync({
        id: orderId,
        status: fulfillment,
      });
    },
    [useSetOrderStatus],
  );

  return (
    <React.Fragment>
      <Card>
        <CardHeader title="Order details" />
        <CardContent>
          <Grid container spacing={3} direction="column">
            {order.items.map((orderItem, index) => (
              <Grid item xs={12} key={index}>
                <Box display="grid" gridTemplateColumns="repeat(18, 1fr)" gridColumn={6} sx={{ width: '75%' }}>
                  <Box gridColumn="span 3">
                    <Box mt={1} display="flex" justifyContent="space-between">
                      <img width="75px" height="75px" src={orderItem.product.variant.image || noPicUrl} alt="" />
                    </Box>
                  </Box>

                  <Box gridColumn="span 4">
                    <Box mt={1} display="flex" justifyContent="space-between">
                      <Box fontWeight="bold">Item description</Box>
                    </Box>
                    <Box mt={1} display="flex" justifyContent="space-between">
                      <Box fontWeight="bold">{orderItem.product.name}</Box>
                    </Box>
                  </Box>
                  <Box gridColumn="span 4">
                    <Box mt={1} display="flex" justifyContent="space-between">
                      <Box fontWeight="bold">SKU:{orderItem.product.variant.sku} </Box>
                    </Box>
                    <Box mt={1} display="flex" justifyContent="space-between">
                      <Box className="text-right"> Price: ${addDecimals(orderItem.amount)}</Box>
                    </Box>
                    <Box mt={1} display="flex" justifyContent="space-between">
                      <Box fontWeight="bold">Quantity: {orderItem.quantity}</Box>
                    </Box>
                  </Box>
                  <Box gridColumn="span 4">
                    <Box mt={1} display="flex" justifyContent="space-between">
                      <Box fontWeight="bold">Fulfillment</Box>
                    </Box>
                    <Box mt={1} display="flex" justifyContent="space-between">
                      <Box>
                        <ScutiSelect
                          placeholder="Select "
                          value={order.status}
                          options={FULFILLMENT_SELECT_OPTIONS}
                          onChange={event => handleFulfillmentChange(order.id, event.target.value as OrderStatus)}
                        />
                      </Box>
                    </Box>
                    <Box mt={1} display="flex" justifyContent="space-between">
                      <Box fontWeight="bold">Tracking number</Box>
                    </Box>
                    <Box mt={1} display="flex" justifyContent="space-between">
                      <Box fontWeight="bold" sx={{ width: '75%' }}>
                        <TextField fullWidth size="small" variant="outlined" />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
      <Card>
        <CardHeader title="Notes" />
        <CardContent>
          <Box m={1}>Order Notes</Box>
          <Box sx={{ width: '35%' }}>
            <ScutiInputNoteOrder
              placeholder="noteOrder"
              order={order}
              initialValue={order.note == null ? '' : order.note}
            />
          </Box>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};
