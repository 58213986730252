import React from 'react';
import { FormGroup, CustomInput } from 'reactstrap';
import { CAMPAIGN_TYPE_OPTIONS } from 'types/select-types';
import { FormikProps } from 'formik';
import { ScutiAutocomplete } from 'components/material/ScutiAutoComplete';
import { useLocation } from 'react-router';
import { useDebounce } from 'use-debounce/lib';
import { useCampaignProductsQuery } from '../__generated__/campaign-edit-page.hooks';
import queryString from 'query-string';
import { Filter_Type } from 'types/__generated__/types';
import { Box, Typography, Card, CardHeader, CardContent } from '@material-ui/core';
import { ScutiSelect } from 'components/material/ScutiSelect';
import { CampaignForm, isPaused } from './CampaignForm.utils';
import { useProductCategories } from 'hooks/useProductCategories';
import { ScutiImgInput } from 'components/material/ScutiImgInput';
import { scutiColors } from 'material-theme/variables';

export const BannerDetails: React.FC<FormikProps<CampaignForm>> = props => {
  const { values, touched, errors, setFieldValue } = props;

  const { search } = useLocation();
  const { shopId } = queryString.parse(search);
  const [searchInput, setSearchInput] = React.useState<string>('');
  const [inputValue] = useDebounce(searchInput, 500);

  const [categorySpecific, setCategorySpecific] = React.useState(!!values.product?.promotion?.promotionType);
  const productCategoriesOptions = useProductCategories();

  const toggleCategory = () => {
    if (categorySpecific) props.setFieldValue('product.campaign', null, true);
    else props.setFieldValue('product.campaign', CAMPAIGN_TYPE_OPTIONS[0].value, true);
    setCategorySpecific(!categorySpecific);
  };
  const campaignProductsQuery = useCampaignProductsQuery(
    {
      id: shopId as string,
      filters: [
        {
          name: 'type',
          operator: Filter_Type.Eq,
          value: ['PRODUCT_LISTING'],
        },
        {
          name: 'name',
          operator: Filter_Type.Contains,
          value: [inputValue],
        },
      ],
    },
    { keepPreviousData: true },
  );

  const products =
    campaignProductsQuery.data?.shopCampaigns.nodes?.reduce(
      (acc: { id: string; name: string; listingId: string }[], { id, product }) => {
        return product?.product ? [...acc, { ...product?.product, listingId: id }] : acc;
      },
      [],
    ) || [];

  const productOptions = products.map(p => ({ value: [p.id, p.listingId], label: p.name }));
  const selectedProduct = values.product?.product || { id: '', name: '' };

  return (
    <Card>
      <CardHeader title="Banner Ad" />
      <CardContent>
        <FormGroup>
          <Box width="100%" height="4rem">
            <ScutiImgInput
              image={values.media?.banner}
              // @ts-ignore
              error={touched.media?.banner && !!errors.media?.banner}
              onChange={file => setFieldValue('media.banner', file, true)}
              disabled={!isPaused(values)}
            >
              <Box
                height="100%"
                pl="2rem"
                pr="2rem"
                display="flex"
                flexDirection="column"
                color={scutiColors.grey200}
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="body2">Upload a 728x90px or 8.09:1 aspect ratio image for your banner.</Typography>
              </Box>
            </ScutiImgInput>
            <Box mb={2}>
              {/* @ts-ignore */}
              {touched.media?.banner && (
                <Typography color="error" variant="caption">
                  {/* @ts-ignore */}
                  {errors.media?.banner}
                </Typography>
              )}
            </Box>
          </Box>
        </FormGroup>
        <Box mt={3}>
          <ScutiAutocomplete
            placeholder="Select Product"
            title="Promoted product"
            getOptionLabel={option => option.label}
            value={productOptions.find(({ value }) => value[0] === selectedProduct.id) || null}
            options={productOptions}
            onInputChange={(_, value, reason) => {
              if ('input' === reason) setSearchInput(value);
            }}
            onChange={(event, value, reason) => {
              if ('selectOption' === reason && !!value) {
                const [id, productListingId] = value.value;
                props.setFieldValue('product.product', { id: id, name: value.label }, true);
                props.setFieldValue('productListingId', productListingId, true);
              }
              if ('clear' === reason || !value) {
                props.setFieldValue('product.product', { id: '', name: '' }, true);
              }
            }}
            onBlur={() => props.setFieldTouched('product.product.id', true, true)}
            // @ts-ignore
            error={touched.product?.product?.id && errors['product.product.id']}
            disabled={!isPaused(values)}
          />
        </Box>
        <Box mt={2}>
          <FormGroup>
            <label>
              <CustomInput
                id="category-box"
                type="checkbox"
                label="Category"
                onChange={toggleCategory}
                checked={categorySpecific}
                disabled={!isPaused(values)}
              />
            </label>
            <ScutiSelect
              title="Category"
              name="category"
              placeholder="Select category"
              value={values.category || ''}
              options={productCategoriesOptions}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              disabled={!categorySpecific || !isPaused(values)}
            />
          </FormGroup>
        </Box>
      </CardContent>
    </Card>
  );
};
