import React, { useMemo } from 'react';
import { FormikProps } from 'formik';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import { ScutiMultipleImgInput } from 'components/material/ScutiMultipleImgInput';

export const CouponAdvertisementImages: React.FC<FormikProps<any>> = ({ values, setFieldValue }) => {
  const modifiedImgs = useMemo(() => {
    // @ts-ignore
    const modImgs = [...new Set(values.coupon?.couponImages)] || [];

    // This moves the default image to the beginning of the images array
    if (values.coupon?.couponImages) {
      const defaultIndex = modImgs.indexOf(values.coupon?.couponImages);
      modImgs.unshift(modImgs.splice(defaultIndex, 1)[0]);
    }

    return modImgs;
  }, [values.coupon?.couponImages]);
  return (
    <Card>
      <CardHeader title="Images" />
      <CardContent>
        <Box>
          <ScutiMultipleImgInput
            images={modifiedImgs}
            onChange={images => setFieldValue('coupon.couponImages', images, true)}
            defaultImage={values.coupon.couponImages[0]}
            onDefaultImageUpdate={image => {
              setFieldValue('defaultImage', image, true);
            }}
          >
            <Box
              mt={10}
              width="100%"
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <PhotoLibraryIcon style={{ width: '12rem', height: '7.5rem' }} width="100%" color="secondary" />
            </Box>
          </ScutiMultipleImgInput>
        </Box>
      </CardContent>
    </Card>
  );
};
