import React from 'react';
import { FormikProps } from 'formik';
import { Box, Typography, Card, CardHeader, CardContent } from '@material-ui/core';
import { CampaignForm, isPaused } from './CampaignForm.utils';
import { ScutiImgInput } from 'components/material/ScutiImgInput';
import { scutiColors } from 'material-theme/variables';

export const ProductBannerDetails: React.FC<FormikProps<CampaignForm>> = props => {
  const { values, errors, setFieldValue } = props;
  console.log('ERRORS : ', errors.media);
  return (
    <Card>
      <CardHeader title="Images" />
      <CardContent>
        <Box>
          <Box width="100%" height="7.5rem" bgcolor="#3b3d44" sx={{ borderRadius: '8px', overflow: 'hidden' }}>
            <ScutiImgInput
              image={values.media?.banner}
              // @ts-ignore
              error={!!errors.media?.banner}
              onChange={file => setFieldValue('media.banner', file, true)}
              disabled={!isPaused(values)}
            >
              <Box
                height="100%"
                pl="2rem"
                pr="2rem"
                display="flex"
                flexDirection="column"
                color="text.white"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="body2">Upload a 728x90 image</Typography>
              </Box>
            </ScutiImgInput>
          </Box>
          <Box>
            <Typography color="error" variant="caption">
              {/* @ts-ignore */}
              {errors.media?.banner}
            </Typography>
          </Box>
          <Box mt={1} color={scutiColors.grey200}>
            Proper file format is .jpeg or .png. Max file size is 5MB.
          </Box>
        </Box>
        <Box mt={4}>
          <Box width="100%" height="10rem" bgcolor="#3b3d44" sx={{ borderRadius: '8px', overflow: 'hidden' }}>
            <ScutiImgInput
              image={values.media?.tile}
              // @ts-ignore
              error={!!errors.media?.tile}
              onChange={file => setFieldValue('media.tile', file, true)}
              disabled={!isPaused(values)}
            >
              <Box
                height="100%"
                pl="2rem"
                pr="2rem"
                display="flex"
                flexDirection="column"
                color="text.white"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="body2">Upload a 300x250 image</Typography>
              </Box>
            </ScutiImgInput>
          </Box>
          <Box>
            <Typography color="error" variant="caption">
              {/* @ts-ignore */}
              {errors.media?.tile}
            </Typography>
          </Box>
          <Box mt={1} color={scutiColors.grey200}>
            Proper file format is .jpeg or .png. Max file size is 5MB.
          </Box>
        </Box>
        <Box mt={4}>
          <Box width="100%" height="15rem" bgcolor="#3b3d44" sx={{ borderRadius: '8px', overflow: 'hidden' }}>
            <ScutiImgInput
              image={values.media?.vertical}
              // @ts-ignore
              error={!!errors.media?.vertical}
              onChange={file => setFieldValue('media.vertical', file, true)}
              disabled={!isPaused(values)}
            >
              <Box
                height="100%"
                pl="2rem"
                pr="2rem"
                display="flex"
                flexDirection="column"
                color="text.white"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="body2">Upload a 300x600 image</Typography>
              </Box>
            </ScutiImgInput>
          </Box>
          <Box>
            <Typography color="error" variant="caption">
              {/* @ts-ignore */}
              {errors.media?.vertical}
            </Typography>
          </Box>
          <Box mt={1} color={scutiColors.grey200}>
            Proper file format is .jpeg or .png. Max file size is 5MB.
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
