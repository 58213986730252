import * as Types from '../../../../types/__generated__/types';

import { ApolloError } from 'apollo-client';
import { useQuery, UseQueryOptions } from 'react-query';
import { fetcher } from 'api';
export type AdvertisementItemFragment = {
  id: string;
  name: string;
  brandId: string;
  cpa?: Types.Maybe<number>;
  category?: Types.Maybe<string>;
  type: Types.AdvertisementType;
  appDownload?: Types.Maybe<{
    appleId?: Types.Maybe<number>;
    androidId?: Types.Maybe<string>;
    pcLink?: Types.Maybe<string>;
  }>;
  coupon?: Types.Maybe<{
    couponLabel?: Types.Maybe<string>;
    couponDescription?: Types.Maybe<string>;
    couponImages?: Types.Maybe<string>;
  }>;
  internal?: Types.Maybe<{
    internalLabel?: Types.Maybe<string>;
    internalHtml5Url?: Types.Maybe<string>;
    internalUnityUrl?: Types.Maybe<string>;
  }>;
  video?: Types.Maybe<{ videoUrl?: Types.Maybe<string> }>;
  noAction?: Types.Maybe<{ noActionLabel?: Types.Maybe<string> }>;
  leadGen?: Types.Maybe<{ affiliateUrl?: Types.Maybe<string> }>;
};

export type AdvertisementsQueryVariables = Types.Exact<{
  paging?: Types.Maybe<Types.PagingInput>;
  sorting?: Types.Maybe<Array<Types.SortFieldInput> | Types.SortFieldInput>;
  filters?: Types.Maybe<Array<Types.FilterInput> | Types.FilterInput>;
}>;

export type AdvertisementsQuery = {
  advertisements: {
    sorting?: Types.Maybe<Array<{ name: string; dir: string }>>;
    paging: { offset: number; limit: number; totalCount: number };
    nodes: Array<AdvertisementItemFragment>;
  };
};

export const AdvertisementItemFragmentDoc = `
    fragment AdvertisementItem on Advertisement {
  id
  name
  brandId
  cpa
  category
  type
  appDownload {
    appleId
    androidId
    pcLink
  }
  coupon {
    couponLabel
    couponDescription
    couponImages
  }
  internal {
    internalLabel
    internalHtml5Url
    internalUnityUrl
  }
  video {
    videoUrl
  }
  noAction {
    noActionLabel
  }
  leadGen {
    affiliateUrl
  }
}
    `;
export const AdvertisementsDocument = `
    query Advertisements($paging: PagingInput, $sorting: [SortFieldInput!], $filters: [FilterInput!]) {
  advertisements(paging: $paging, sorting: $sorting, filters: $filters) {
    sorting {
      name
      dir
    }
    paging {
      offset
      limit
      totalCount
    }
    nodes {
      ...AdvertisementItem
    }
  }
}
    ${AdvertisementItemFragmentDoc}`;
export const useAdvertisementsQuery = <TData = AdvertisementsQuery, TError = ApolloError>(
  variables?: AdvertisementsQueryVariables,
  options?: UseQueryOptions<AdvertisementsQuery, TError, TData>,
) =>
  useQuery<AdvertisementsQuery, TError, TData>(
    ['Advertisements', variables],
    fetcher<AdvertisementsQuery, AdvertisementsQueryVariables>(AdvertisementsDocument, variables),
    options,
  );
