import React, { useCallback } from 'react';
import { FormControl, TextField, TextFieldProps } from '@material-ui/core';
import { Tooltip } from '@mui/material';
import { RemoveAllToasts, useToasts } from 'react-toast-notifications';
import { useSetVendorOrderMutation } from '../../pages/Orders/__generated__/orders.hooks';

interface Props extends Omit<TextFieldProps, 'error' | 'helperText'> {
  title?: string;
  error?: React.ReactNode;
  hideHelper?: boolean;
  initialValue: string;
  id: string;
}

export const ScutiInputVendorOrder: React.FC<Props> = ({ title, error, hideHelper, initialValue, id, ...props }) => {
  const [value, setValue] = React.useState(initialValue);
  const { addToast, removeAllToasts } = useToasts();
  const useVendorOrder = useSetVendorOrderMutation();
  const onChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setValue(e.target.value);
  };

  const handleVendorOrderChange = useCallback(
    async (orderId: string, vendorId: string, useVendorOrder: any) => {
      removeAllToasts();
      try {
        await useVendorOrder.mutateAsync({
          id: orderId,
          vendorOrderId: vendorId,
        });
        addToast('Value: ' + vendorId + '  updated successful ', {
          appearance: 'success',
          autoDismiss: true,
          placement: 'top-center',
        });
      } catch (error) {
        console.error(error);
        const message = 'Order # ' + vendorId + ' already exists, please reconfirm order ';
        addToast(message, { appearance: 'error', autoDismiss: true, autoDismissTimeout: 10000 });
      }
    },
    [useVendorOrder],
  );

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === 'Enter') {
      handleVendorOrderChange(id, value, useVendorOrder).then(r => {});
    }
  };

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <FormControl fullWidth>
      {title && <label style={{ opacity: props.disabled ? 0.4 : 1 }}>{title}</label>}
      <Tooltip title="Please enter for input">
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          error={!!error}
          helperText={hideHelper ? undefined : error}
          value={value}
          onKeyDown={handleKeyDown}
          onChange={onChange}
          {...props}
        />
      </Tooltip>
    </FormControl>
  );
};
