import { Box, Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import React from 'react';
import { OrderFragment } from '../__generated__/orders.hooks';

interface Props {
  order: OrderFragment;
}

export const OrderCustomerInformation: React.FC<Props> = ({ order }) => {
  const { user, shippingInformation } = order;
  const { fullName, email } = user;
  return (
    <React.Fragment>
      <Card>
        <CardHeader title="Customer information" />
        <CardContent>
          <Box>
            <Box>
              <strong>{fullName}</strong>
            </Box>
            <Box mt={1}>
              <a href={`mailto:${email}`}>{email}</a>
            </Box>
            <Box mt={1}>Phone: {shippingInformation?.phone || '---'}</Box>
          </Box>
          <Box mt={2}>
            <b>Shipping address</b>
          </Box>
          <Box mt={2}>{order.user.fullName}</Box>
          <Box>{order.shippingInformation?.address1}</Box>
          <Box>
            {order.shippingInformation?.city}, {order.shippingInformation?.state}
          </Box>
          <Box>{order.shippingInformation?.zipCode}</Box>
          <Box>{order.shippingInformation?.country}</Box>
        </CardContent>
      </Card>
      <Card>
        <CardHeader title="Fulfillmet" />
        <CardContent>
          <Box mt={1} sx={{ height: '15%' }}>
            <Box mt={3}>Shopping Method</Box>
            <Box>{order.status}</Box>
          </Box>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};
