import React, { useCallback } from 'react';
import { FormControl, TextField, TextFieldProps } from '@material-ui/core';
import { Tooltip } from '@mui/material';
import { useToasts } from 'react-toast-notifications';
import { RefundOrderInput, RefundOrderInputItem } from '../../types/__generated__/types';
import { FocusedInputShape } from 'react-dates';
interface Props extends Omit<TextFieldProps, 'error' | 'helperText'> {
  title?: string;
  error?: React.ReactNode;
  hideHelper?: boolean;
  initialValue: string;
  userId: string;
  orderId: string;
  orderItemId: string;
  reason: string;
  useRefundOrder: any;
}

export const ScutiInputRefundOrder: React.FC<Props> = ({
  title,
  error,
  hideHelper,
  useRefundOrder,
  initialValue,
  orderItemId,
  userId,
  orderId,
  reason,
  ...props
}) => {
  const [refundInput, setRefundInput] = React.useState<RefundOrderInput>({
    userId: userId,
    orderId: orderId,
    items: [
      {
        orderItemId: orderItemId,
        refundQuantity: 0.0,
        activated: false,
        currencyName: 'USD',
        reason: '',
      },
    ],
  });

  const [value, setValue] = React.useState(initialValue);
  const { addToast, removeAllToasts } = useToasts();

  const onChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setValue(e.target.value);
  };

  const handleRefundOrderChange = useCallback(
    async (input: RefundOrderInput, amount, reason, useRefundOrder: any) => {
      removeAllToasts();
      refundInput.items[0].refundQuantity = Number(amount);
      refundInput.items[0].reason = reason;
      setRefundInput(refundInput);

      try {
        await useRefundOrder.mutateAsync({
          input: refundInput,
        });
        addToast('Value updated successful ', {
          appearance: 'success',
          autoDismiss: true,
          placement: 'top-center',
        });
      } catch (error) {
        const message = 'Refund for Order Id# ' + refundInput.orderId + 'did not update properly  ';
        addToast(message, { appearance: 'error', autoDismiss: true, autoDismissTimeout: 10000 });
      }
    },
    [useRefundOrder, addToast],
  );

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === 'Enter') {
      handleRefundOrderChange(refundInput, value, reason, useRefundOrder).then(r => {});
    }
  };

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  // @ts-ignore
  return (
    <FormControl fullWidth>
      {title && <label style={{ opacity: props.disabled ? 0.4 : 1 }}>{title}</label>}
      <Tooltip title="Please enter for input">
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          error={!!error}
          helperText={hideHelper ? undefined : error}
          value={value}
          onKeyDown={handleKeyDown}
          onChange={onChange}
          {...props}
        />
      </Tooltip>
    </FormControl>
  );
};
