import React from 'react';
import { ScutiInput } from 'components/material/ScutiInput';
import { FormikProps } from 'formik';
import { ScutiSelect } from 'components/material/ScutiSelect';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { useProductCategories } from 'hooks/useProductCategories';

export const InternalAdvertisementDetails: React.FC<FormikProps<any>> = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
}) => {
  const productCategoriesOptions = useProductCategories();

  return (
    <Card>
      <CardHeader title="Details" />
      <CardContent>
        <Box>
          <ScutiInput
            title="Label"
            name="internal.internalLabel"
            placeholder="Title of Ad"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.internal?.internalLabel}
            error={
              values.id
                ? errors['internal.internalLabel']
                : // @ts-ignore
                  touched.internal?.internalLabel && errors['internal.internalLabel']
            }
          />
        </Box>
        <Box mt={2}>
          <ScutiInput
            title="Internal HTML5 URL"
            name="internal.internalHtml5Url"
            placeholder="URL"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.internal?.internalHtml5Url}
            error={
              values.id
                ? errors['internal.internalHtml5Url']
                : // @ts-ignore
                  touched.internal?.internalHtml5Url && errors['internal.internalHtml5Url']
            }
          />
        </Box>
        <Box mt={2}>
          <ScutiInput
            title="Internal Unity URL"
            name="internal.internalUnityUrl"
            placeholder="URL"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.internal?.internalUnityUrl}
            error={
              values.id
                ? errors['internal.internalUnityUrl']
                : // @ts-ignore
                  touched.internal?.internalUnityUrl && errors['internal.internalUnityUrl']
            }
          />
        </Box>
        <Box mt={2}>
          <ScutiSelect
            name="category"
            title="Category *"
            placeholder="Select category"
            value={values.category}
            error={touched.category && errors.category}
            onChange={handleChange}
            onBlur={handleBlur}
            options={productCategoriesOptions}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
