import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from 'reactstrap';
import { ProductGridItem } from './ProductGridItem';
import { productsRoutes } from 'routing';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useDebounce } from 'use-debounce/lib';
import { useProductsQuery, useSyncEbridgeMutation } from './__generated__/products-list-page.hooks';
import { CampaignType, Filter_Type } from 'types/__generated__/types';
import { Grid } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { TopNavigation } from 'components/material/TopNavigation';
import { PaginationLayout } from 'layouts/PaginationLayout';
import { usePaging } from 'hooks/usePaging';
import { useAppStore } from 'store/app-store.hook';

export const ProductsListPage: React.FC = observer(() => {
  const { selectedItem } = useAppStore().uiStore;
  const { addToast } = useToasts();
  const paging = usePaging();
  const [searchInput, setSearchInput] = React.useState<string>('');
  const [inputValue] = useDebounce(searchInput, 500);
  const ebridgeMutation = useSyncEbridgeMutation();
  const productsQuery = useProductsQuery(
    {
      id: selectedItem.id,
      paging,
      filters: [
        {
          name: 'name',
          operator: Filter_Type.Like,
          value: [inputValue],
        },
      ],
    },
    {
      keepPreviousData: true,
    },
  );

  React.useEffect(() => {
    if (productsQuery.error) addToast(productsQuery.error.message, { appearance: 'error', autoDismiss: false });
  }, [addToast, productsQuery.error]);

  const syncEbridge = React.useCallback(async () => {
    try {
      await ebridgeMutation.mutateAsync({ input: { id: selectedItem.id } });
      productsQuery.refetch();
    } catch (error) {
      addToast(error.message, { appearance: 'error', autoDismiss: false });
    }
  }, [ebridgeMutation, selectedItem.id, productsQuery, addToast]);

  const isLoading = productsQuery.isLoading;
  const products = productsQuery.data?.shopProducts.nodes || [];

  const pagingResponse = productsQuery.data?.shopProducts.paging;
  const showProducts = !!products.length && !productsQuery.isLoading;

  return (
    <PaginationLayout
      isLoading={isLoading}
      paging={pagingResponse}
      header={
        <TopNavigation title="Products management">
          <Grid container justifyContent="flex-end" alignItems="center" spacing={1}>
            <Grid item md={4}>
              <ScutiInput
                placeholder="Search..."
                value={searchInput}
                onChange={({ target }) => setSearchInput(target.value)}
              />
            </Grid>
            <Grid item md={2}>
              <Button
                className="btn btn-secondary d-block"
                disabled={!selectedItem?.shopifyName}
                onClick={syncEbridge}
                style={{ width: '100%' }}
              >
                {ebridgeMutation.isLoading ? `${'      ...     '}` : 'Shopify sync'}
              </Button>
            </Grid>
            <Grid item md={2}>
              <Link className="btn btn-primary d-block" to={`${productsRoutes.PRODUCT_NEW}?shopId=${selectedItem.id}`}>
                Add Product
              </Link>
            </Grid>
          </Grid>
        </TopNavigation>
      }
      content={
        showProducts ? (
          <Grid container spacing={3}>
            {products.map((product, index) => (
              <Grid item xs={3} key={`product_item_${index}`}>
                <ProductGridItem product={product} url={productsRoutes.PRODUCT_OVERVIEW(product.id)} />
              </Grid>
            ))}
          </Grid>
        ) : null
      }
    />
  );
});
