import React, { Suspense, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from 'layouts/ErrorFallback';
import { DefaultHeader } from './DefaultHeader';
import { Box, CssBaseline, Drawer, ThemeProvider } from '@mui/material';
import { theme } from 'material-theme';
import { scutiColors } from 'material-theme/variables';
const LeftNavigation = React.lazy(() =>
  import('./LeftNavigation').then(module => ({
    default: module.LeftNavigation,
  })),
);

export const DefaultLayout: React.FC = observer(({ children }) => {
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);

  const drawerWidth = 240;

  const loading = () => (
    <div className="animated fadeIn pt-1 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );
  return (
    <div className="app">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box
          sx={{
            fontFamily: 'Industry',
            width: '100%',
            position: 'fixed',
            display: 'flex',
            paddingTop: '8px',
            paddingBottom: '8px',
            paddingLeft: '8px',
            paddingRight: '24px',
            backgroundColor: scutiColors.grey500,
            zIndex: 50,
          }}
        >
          <DefaultHeader handleDrawerToggle={() => setMobileOpen(!mobileOpen)} drawerWidth={drawerWidth} />
        </Box>
        <Box className="app-body" sx={{ display: 'flex' }}>
          <Box component="nav" sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 }, marginTop: '55px' }}>
            <Drawer
              anchor="left"
              variant="temporary"
              open={mobileOpen}
              onClose={() => setMobileOpen(!mobileOpen)}
              sx={{
                backgroundColor: 'transparent',
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  xs: { width: drawerWidth, border: 'none', backgroundColor: scutiColors.grey500 },
                  lg: {
                    marginTop: '55px',
                    zIndex: 10,
                    width: drawerWidth,
                    border: 'none',
                    backgroundColor: scutiColors.grey500,
                  },
                },
                display: { xs: 'block', lg: 'none' },
              }}
            >
              <Suspense fallback={loading()}>
                <LeftNavigation />
              </Suspense>
            </Drawer>
            <Drawer
              anchor="left"
              variant="permanent"
              open={true}
              onClose={() => setMobileOpen(!mobileOpen)}
              sx={{
                backgroundColor: 'transparent',
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  xs: { width: drawerWidth, border: 'none', backgroundColor: scutiColors.grey500 },
                  lg: {
                    marginTop: '55px',
                    zIndex: 10,
                    width: drawerWidth,
                    border: 'none',
                    backgroundColor: scutiColors.grey500,
                  },
                },
                display: { xs: 'none', lg: 'block' },
              }}
            >
              <Suspense fallback={loading()}>
                <LeftNavigation />
              </Suspense>
            </Drawer>
          </Box>
          <Box sx={{ flexGrow: 1, p: 3, marginTop: '32px', width: `calc(100% - ${drawerWidth}px)` }}>
            <main className="main">
              <Box>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={loading()}>{children}</Suspense>
                </ErrorBoundary>
              </Box>
            </main>
          </Box>
        </Box>
      </ThemeProvider>
    </div>
  );
});

export const withDefaultLayout = (Page: React.ComponentType) => () => (
  <DefaultLayout>
    <Page />
  </DefaultLayout>
);
