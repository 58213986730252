import { CampaignBudgetOption, CampaignType, NewCampaignInput } from 'types/__generated__/types';
import { FormValidationSchema, transformedNumber } from 'utils/form-utils';
import { getImgAspectRatio, uploadImage } from 'utils/upload-image.utils';
import * as Yup from 'yup';
import { CampaignForm } from './CampaignForm.utils';

export const bannerCampaignFormDefaults = (): CampaignForm => ({
  name: '',
  category: null,
  video: null,
  type: CampaignType.Banner,
  budget: {
    option: CampaignBudgetOption.Normal,
    maxSpend: 0,
    maxDailySpend: 0,
    limitOfImpressions: 0,
  },
  reward: {
    scutiPercentage: 0,
  },
  media: {
    banner: null,
  },
  demographic: {
    gender: null,
    minAge: 0,
    maxAge: 100,
  },
  duration: {
    start: null,
    end: null,
    inventory: null,
    runUntilStockZero: null,
  },
  extras: { moreExposure: false, interstitials: false },
});

interface CampaignInput extends Omit<NewCampaignInput, 'id'> {
  id?: string;
}

const mapMedia = async (media: CampaignForm['media']) => {
  if (!media) return media;
  const { banner } = media;
  return {
    banner: typeof banner === 'object' ? (await uploadImage(banner!, { height: 90, width: 728 })).url : banner,
  };
};

export const mapBannerCampaignToInput = async (campaign: CampaignForm, shopId: string): Promise<CampaignInput> => {
  return {
    ...campaign,
    product: null,
    shopId,
    game: {},
    location: {},
    media: campaign.media && (await mapMedia(campaign.media)),
  };
};

const FILE_SIZE = 3 * 1024 * 1024;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

export const validationSchema = (): FormValidationSchema<CampaignForm> => {
  return Yup.object().shape({
    type: Yup.mixed().oneOf([CampaignType.Banner]),
    name: Yup.string()
      .min(3, 'Name should be at least 3 characters.')
      .max(25, 'Name can be 25 characters or less.')
      .required('Name is required!'),
    reward: Yup.object().shape({
      scutiPercentage: Yup.number()
        .min(0)
        .max(100),
    }),
    media: Yup.object().shape({
      banner: Yup.mixed()
        .required('Banner is required')
        .nullable()
        .test('fileSize', 'File too large', value => {
          if (!value) return false;
          return typeof value === 'string' || value.size <= FILE_SIZE;
        })
        .test('fileFormat', 'Unsupported Format', value => {
          if (!value) return false;
          return typeof value === 'string' || SUPPORTED_FORMATS.includes(value.type);
        })
        .test('aspectRatio', 'Wrong aspect ratio', async value => {
          if (!value) return false;
          if (typeof value === 'string') return true;
          const [width, height] = await getImgAspectRatio(value);
          return width === 364 && height === 45;
        }),
    }),
    demographic: Yup.object().shape({
      gender: Yup.string().nullable(),
      minAge: Yup.number()
        .min(0)
        .max(100)
        .required(),
      maxAge: Yup.number()
        .min(0)
        .max(100)
        .required(),
    }),
    duration: Yup.object().shape({
      start: Yup.date().nullable(),
      end: Yup.date().nullable(),
      runUntilStockZero: Yup.boolean().nullable(),
      inventory: Yup.number().nullable(),
    }),
    location: Yup.object()
      .shape({
        country: Yup.string().nullable(),
        states: Yup.array()
          .of(Yup.string())
          .nullable(),
      })
      .nullable(),
    extras: Yup.object().shape({
      moreExposure: Yup.boolean().nullable(),
      interstitials: Yup.boolean().nullable(),
    }),
    budget: Yup.object().shape({
      maxSpend: transformedNumber.moreThan(0, 'Cannot be 0 or less').required('Max Spend is required!'),
      maxDailySpend: transformedNumber.moreThan(0, 'Cannot be 0 or less').required('Daily Max Spend is required!'),
      limitOfImpressions: transformedNumber.min(6, 'Cannot be less then 6').required('Daily Max Spend is required!'),
    }),
  });
};
