import React from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, useLocation, useParams } from 'react-router';
import { CreateAdvertisement } from './CreateAdvertisement';
import { Loader } from 'components/Loader';
import { advertisementRoutes } from 'routing';
import { Box } from '@material-ui/core';
import {
  AppDownloadAdvertisementEditForm,
  VideoAdvertisementEditForm,
  CouponAdvertisementEditForm,
  InternalAdvertisementEditForm,
  NoActionAdvertisementEditForm,
  LeadGenAdvertisementEditForm,
} from './AdvertisementEditForms';
import queryString from 'query-string';
import { useToasts } from 'react-toast-notifications';
import { mapAppDownloadAdvertisementToInput } from './AdvertisementEditForms/AppDownloadAdvertisementEditForm.utils';
import { AdvertisementForm } from './AdvertisementEditForms/AdvertisementForm.utils';
import { hasId } from 'types/util-types';
import { AdvertisementType } from 'types/__generated__/types';
import { mapVideoAdvertisementToInput } from './AdvertisementEditForms/VideoAdvertisementEditForm.utils';
import { mapCouponAdvertisementToInput } from './AdvertisementEditForms/CouponAdvertisementEditForm.utils';
import { mapInternalAdvertisementToInput } from './AdvertisementEditForms/InternalAdvertisementEditForm.utils';
import { mapNoActionAdvertisementToInput } from './AdvertisementEditForms/NoActionAdvertisementEditForm.utils';
import { mapLeadGenAdvertisementToInput } from './AdvertisementEditForms/LeadGenAdvertisementEditForm.utils';
import {
  useAdvertisementQuery,
  useCreateAdvertisementMutation,
  useUpdateAdvertisementMutation,
} from './__generated__/advertisement-edit-page.hooks';

const saveHandler: any = {
  [AdvertisementType.AppDownload]: mapAppDownloadAdvertisementToInput,
  [AdvertisementType.VideoPromo]: mapVideoAdvertisementToInput,
  [AdvertisementType.Internal]: mapInternalAdvertisementToInput,
  [AdvertisementType.NoActionAd]: mapNoActionAdvertisementToInput,
  [AdvertisementType.Coupon]: mapCouponAdvertisementToInput,
  [AdvertisementType.LeadGen]: mapLeadGenAdvertisementToInput,
};

export const AdvertisementEditPage: React.FC = observer(() => {
  const { advertisementId } = useParams<{
    advertisementId: string;
  }>();
  const { search } = useLocation();
  const searchQuery = queryString.parse(search);

  const [advertisementType, setAdvertisementType] = React.useState<AdvertisementType | null>(null);
  const history = useHistory();
  const { addToast } = useToasts();

  const advertisementQuery = useAdvertisementQuery({ id: advertisementId }, { enabled: !!advertisementId });

  let advertisement = advertisementQuery.data?.advertisement;
  const brandId = 'dee1645e-2482-11ed-861d-0242ac120002'; //(searchQuery.brandId as string) || advertisement?.brandId;

  const useUpdateAdvertisement = useUpdateAdvertisementMutation();
  const useCreateAdvertisement = useCreateAdvertisementMutation();

  const advertisementToRender = advertisementType || advertisement?.type;
  const isNew = !(advertisementType || advertisementId);

  React.useEffect(() => {
    if (advertisementQuery.error)
      addToast(advertisementQuery.error.message, {
        appearance: 'error',
        autoDismiss: false,
      });
  }, [addToast, advertisementQuery.error]);

  const saveOrUpdate = React.useCallback(
    async ({ ...advertisement }: AdvertisementForm) => {
      const handler = saveHandler[advertisement.type];
      if (hasId(advertisement)) {
        try {
          await useUpdateAdvertisement.mutateAsync({
            // @ts-ignore
            input: { ...(await handler(advertisement, brandId as string)), id: advertisement.id },
          });
          await advertisementQuery.refetch();
          addToast('Advertisement has been updated!', { appearance: 'success', autoDismiss: true });
        } catch (e) {
          addToast(e.message, { appearance: 'error', autoDismiss: false });
        }
      } else {
        try {
          await useCreateAdvertisement.mutateAsync({
            // @ts-ignore
            input: await handler(advertisement, brandId as string),
          });
          addToast('Advertisement has been created!', { appearance: 'success', autoDismiss: true });
          history.push(advertisementRoutes.ADVERTISEMENTS);
        } catch (e) {
          addToast(e.message, { appearance: 'error', autoDismiss: false });
        }
      }
    },
    [addToast, history, brandId, advertisementQuery, useCreateAdvertisement, useUpdateAdvertisement],
  );

  const isLoading = advertisementQuery.isLoading;

  if (isLoading) return <Loader />;

  return isNew ? (
    <CreateAdvertisement linkBack={advertisementRoutes.ADVERTISEMENTS} onSelect={setAdvertisementType} />
  ) : (
    <Box>
      {advertisementToRender === AdvertisementType.NoActionAd && (
        <NoActionAdvertisementEditForm
          advertisement={advertisement as AdvertisementForm}
          linkBack={advertisementRoutes.ADVERTISEMENTS}
          onSave={saveOrUpdate}
        />
      )}
      {advertisementToRender === AdvertisementType.VideoPromo && (
        <VideoAdvertisementEditForm
          advertisement={advertisement as AdvertisementForm}
          linkBack={advertisementRoutes.ADVERTISEMENTS}
          onSave={saveOrUpdate}
        />
      )}
      {advertisementToRender === AdvertisementType.Coupon && (
        <CouponAdvertisementEditForm
          advertisement={advertisement as AdvertisementForm}
          linkBack={advertisementRoutes.ADVERTISEMENTS}
          onSave={saveOrUpdate}
        />
      )}
      {advertisementToRender === AdvertisementType.AppDownload && (
        <AppDownloadAdvertisementEditForm
          advertisement={advertisement as AdvertisementForm}
          linkBack={advertisementRoutes.ADVERTISEMENTS}
          onSave={saveOrUpdate}
        />
      )}
      {advertisementToRender === AdvertisementType.Internal && (
        <InternalAdvertisementEditForm
          advertisement={advertisement as AdvertisementForm}
          linkBack={advertisementRoutes.ADVERTISEMENTS}
          onSave={saveOrUpdate}
        />
      )}
      {advertisementToRender === AdvertisementType.LeadGen && (
        <LeadGenAdvertisementEditForm
          advertisement={advertisement as AdvertisementForm}
          linkBack={advertisementRoutes.ADVERTISEMENTS}
          onSave={saveOrUpdate}
        />
      )}
    </Box>
  );
});
