import * as Types from '../../../types/__generated__/types';

import { ApolloError } from 'apollo-client';
import { useQuery, UseQueryOptions } from 'react-query';
import { fetcher } from 'api';
export type UserGameItemsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type UserGameItemsQuery = { games: { nodes: Array<{ id: string; name: string }> } };

export type UserShopItemsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type UserShopItemsQuery = {
  shops: { nodes: Array<{ id: string; name: string; shopifyName?: Types.Maybe<string> }> };
};

export const UserGameItemsDocument = `
    query UserGameItems {
  games(paging: {offset: 0, limit: 50}) {
    nodes {
      id
      name
    }
  }
}
    `;
export const useUserGameItemsQuery = <TData = UserGameItemsQuery, TError = ApolloError>(
  variables?: UserGameItemsQueryVariables,
  options?: UseQueryOptions<UserGameItemsQuery, TError, TData>,
) =>
  useQuery<UserGameItemsQuery, TError, TData>(
    ['UserGameItems', variables],
    fetcher<UserGameItemsQuery, UserGameItemsQueryVariables>(UserGameItemsDocument, variables),
    options,
  );
export const UserShopItemsDocument = `
    query UserShopItems {
  shops(paging: {offset: 0, limit: 50}) {
    nodes {
      id
      name
      shopifyName
    }
  }
}
    `;
export const useUserShopItemsQuery = <TData = UserShopItemsQuery, TError = ApolloError>(
  variables?: UserShopItemsQueryVariables,
  options?: UseQueryOptions<UserShopItemsQuery, TError, TData>,
) =>
  useQuery<UserShopItemsQuery, TError, TData>(
    ['UserShopItems', variables],
    fetcher<UserShopItemsQuery, UserShopItemsQueryVariables>(UserShopItemsDocument, variables),
    options,
  );
