import React from 'react';
import { ScutiInput } from 'components/material/ScutiInput';
import { FormikProps } from 'formik';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';

export const LeadGenAdvertisementDetails: React.FC<FormikProps<any>> = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
}) => {
  return (
    <Card>
      <CardHeader title="Details" />
      <CardContent>
        <Box mt={2}>
          <ScutiInput
            title="CPA"
            name="cpa"
            type="number"
            placeholder="$1.00"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.cpa || ''}
            error={values.id ? errors['cpa'] : touched.cpa && errors['cpa']}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
