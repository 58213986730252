import React from 'react';
import { ScutiInput } from 'components/material/ScutiInput';
import { FormikProps } from 'formik';
import { ScutiSelect } from 'components/material/ScutiSelect';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { useProductCategories } from 'hooks/useProductCategories';

export const NoActionAdvertisementDetails: React.FC<FormikProps<any>> = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
}) => {
  const productCategoriesOptions = useProductCategories();

  return (
    <Card>
      <CardHeader title="Details" />
      <CardContent>
        <Box>
          <ScutiInput
            title="Label"
            name="noAction.noActionLabel"
            placeholder="Title of Ad"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.noAction?.noActionLabel}
            error={
              values.id
                ? errors['noAction.noActionLabel']
                : // @ts-ignore
                  touched.noAction?.noActionLabel && errors['noAction.noActionLabel']
            }
          />
        </Box>
        <Box mt={2}>
          <ScutiSelect
            name="category"
            title="Category *"
            placeholder="Select category"
            value={values.category}
            error={touched.category && errors.category}
            onChange={handleChange}
            onBlur={handleBlur}
            options={productCategoriesOptions}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
