import * as Types from '../../../../types/__generated__/types';

import { ApolloError } from 'apollo-client';
import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from 'react-query';
import { fetcher } from 'api';
export type AdvertisementFullFragment = {
  id: string;
  name: string;
  brandId: string;
  cpa?: Types.Maybe<number>;
  category?: Types.Maybe<string>;
  type: Types.AdvertisementType;
  appDownload?: Types.Maybe<{
    appleId?: Types.Maybe<number>;
    androidId?: Types.Maybe<string>;
    pcLink?: Types.Maybe<string>;
  }>;
  coupon?: Types.Maybe<{
    couponLabel?: Types.Maybe<string>;
    couponDescription?: Types.Maybe<string>;
    couponImages?: Types.Maybe<string>;
  }>;
  internal?: Types.Maybe<{
    internalLabel?: Types.Maybe<string>;
    internalHtml5Url?: Types.Maybe<string>;
    internalUnityUrl?: Types.Maybe<string>;
  }>;
  video?: Types.Maybe<{ videoUrl?: Types.Maybe<string> }>;
  noAction?: Types.Maybe<{ noActionLabel?: Types.Maybe<string> }>;
  leadGen?: Types.Maybe<{ affiliateUrl?: Types.Maybe<string> }>;
};

export type AdvertisementQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type AdvertisementQuery = { advertisement: AdvertisementFullFragment };

export type CreateAdvertisementMutationVariables = Types.Exact<{
  input: Types.NewAdvertisementInput;
}>;

export type CreateAdvertisementMutation = { createAdvertisement: AdvertisementFullFragment };

export type UpdateAdvertisementMutationVariables = Types.Exact<{
  input: Types.UpdateAdvertisementInput;
}>;

export type UpdateAdvertisementMutation = { updateAdvertisement: AdvertisementFullFragment };

export const AdvertisementFullFragmentDoc = `
    fragment AdvertisementFull on Advertisement {
  id
  name
  brandId
  cpa
  category
  type
  appDownload {
    appleId
    androidId
    pcLink
  }
  coupon {
    couponLabel
    couponDescription
    couponImages
  }
  internal {
    internalLabel
    internalHtml5Url
    internalUnityUrl
  }
  video {
    videoUrl
  }
  noAction {
    noActionLabel
  }
  leadGen {
    affiliateUrl
  }
}
    `;
export const AdvertisementDocument = `
    query Advertisement($id: String!) {
  advertisement(id: $id) {
    ...AdvertisementFull
  }
}
    ${AdvertisementFullFragmentDoc}`;
export const useAdvertisementQuery = <TData = AdvertisementQuery, TError = ApolloError>(
  variables: AdvertisementQueryVariables,
  options?: UseQueryOptions<AdvertisementQuery, TError, TData>,
) =>
  useQuery<AdvertisementQuery, TError, TData>(
    ['Advertisement', variables],
    fetcher<AdvertisementQuery, AdvertisementQueryVariables>(AdvertisementDocument, variables),
    options,
  );
export const CreateAdvertisementDocument = `
    mutation createAdvertisement($input: NewAdvertisementInput!) {
  createAdvertisement(input: $input) {
    ...AdvertisementFull
  }
}
    ${AdvertisementFullFragmentDoc}`;
export const useCreateAdvertisementMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<CreateAdvertisementMutation, TError, CreateAdvertisementMutationVariables, TContext>,
) =>
  useMutation<CreateAdvertisementMutation, TError, CreateAdvertisementMutationVariables, TContext>(
    (variables?: CreateAdvertisementMutationVariables) =>
      fetcher<CreateAdvertisementMutation, CreateAdvertisementMutationVariables>(
        CreateAdvertisementDocument,
        variables,
      )(),
    options,
  );
export const UpdateAdvertisementDocument = `
    mutation updateAdvertisement($input: UpdateAdvertisementInput!) {
  updateAdvertisement(input: $input) {
    ...AdvertisementFull
  }
}
    ${AdvertisementFullFragmentDoc}`;
export const useUpdateAdvertisementMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<UpdateAdvertisementMutation, TError, UpdateAdvertisementMutationVariables, TContext>,
) =>
  useMutation<UpdateAdvertisementMutation, TError, UpdateAdvertisementMutationVariables, TContext>(
    (variables?: UpdateAdvertisementMutationVariables) =>
      fetcher<UpdateAdvertisementMutation, UpdateAdvertisementMutationVariables>(
        UpdateAdvertisementDocument,
        variables,
      )(),
    options,
  );
