import React, { useMemo } from 'react';
import { FormikProps } from 'formik';
import { Card, CardHeader, CardContent, FormGroup, Box } from '@material-ui/core';
import { CustomInput } from 'reactstrap';
import { CampaignForm, isPaused } from './CampaignForm.utils';
import { scutiColors } from 'material-theme/variables';

export const EnhancementsDetails: React.FC<FormikProps<CampaignForm>> = props => {
  const { values, setFieldValue, handleBlur } = props;
  const onMoreExposureChange = () => {
    setFieldValue(`extras.moreExposure`, !values.extras.moreExposure, true);
  };
  const onInterstitialsChange = () => {
    setFieldValue(`extras.interstitials`, !values.extras.interstitials, true);
  };
  useMemo(() => {
    if (!values.media?.tile) {
      setFieldValue(`extras.interstitials`, false, true);
    }
  }, [values.media?.tile]);
  return (
    <Card>
      <CardHeader title="Extras" />
      <CardContent>
        <Box>
          <FormGroup>
            <label htmlFor={`extras-more-exposure`}>
              <CustomInput
                id="extras-more-exposure"
                type="checkbox"
                name="extras.moreExposure"
                label="More exposure"
                onChange={onMoreExposureChange}
                onBlur={handleBlur}
                checked={!!values.extras.moreExposure}
                disabled={!isPaused(values)}
              />
            </label>
            <Box color={scutiColors.grey200}>Grab more attention by adding a glowing border to your product.</Box>
          </FormGroup>
        </Box>
        <Box mt={2}>
          <FormGroup>
            <label htmlFor={`extras-interstitials`}>
              <CustomInput
                id="extras-interstitials"
                type="checkbox"
                name="extras.interstitials"
                label="Interstitials"
                onChange={onInterstitialsChange}
                onBlur={handleBlur}
                checked={!!values.extras.interstitials}
                disabled={!values.media?.tile || !isPaused(values)}
              />
            </label>
            <Box color={scutiColors.grey200}>Must have 300 x 250 image available.</Box>
          </FormGroup>
        </Box>
      </CardContent>
    </Card>
  );
};
