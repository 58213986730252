import { Box, Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    padding: `${theme.spacing(2)}px !important`,
    height: '100%',
  },
  title: {
    color: '#9DA5B1',
  },
  blackBold: {
    color: 'black',
    fontWeight: 'bold',
  },
}));

interface Props {
  title: string;
  icon?: React.ReactNode;
  value: string | number;
  subValue?: string;
  isLoading?: boolean;
}

export const StatsCard: React.FC<Props> = ({ title, icon, value, subValue, isLoading }) => {
  const classes = useStyles();
  return (
    <Card style={{ borderRadius: '10px', width: '100%', height: '100%' }}>
      <CardContent className={classes.root}>
        <Box display="flex" width="100%" flexDirection="column" height={subValue ? 102 : 74} minWidth={212}>
          <Box display="flex" flexDirection="row" sx={{ alignItems: 'center', minHeight: '20px' }}>
            {icon && (
              <Box mr={1} sx={{ display: 'flex' }}>
                {icon}
              </Box>
            )}
            <Box fontWeight={600} className={classes.title}>
              <Typography variant="body1">{title}</Typography>
            </Box>
          </Box>
          {isLoading ? (
            <Box display="flex" width="100%" height="100%" alignItems="center" justifyContent="center">
              <CircularProgress color="inherit" />
            </Box>
          ) : (
            <Box mt={1} mb={1} fontWeight="fontWeightBold" fontSize={36}>
              <Typography variant="h1" className={classes.blackBold}>
                {value}
              </Typography>
            </Box>
          )}
          <Typography variant="body2" className={classes.title} component="div">
            <Box fontWeight={600}>{subValue}</Box>
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};
