import React from 'react';
import { Form, Button } from 'reactstrap';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { validate } from 'utils/form-utils';
import { noActionAdvertisementFormDefaults, validationSchema } from './NoActionAdvertisementEditForm.utils';
import { Box, Grid } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { AdvertisementForm } from './AdvertisementForm.utils';
import { NoActionAdvertisementDetails } from './NoActionAdvertisementDetails';

interface Props {
  linkBack: string;
  advertisement?: AdvertisementForm;
  onSave: (advertisement: AdvertisementForm) => void;
}

export const NoActionAdvertisementEditForm: React.FC<Props> = ({ advertisement, linkBack, onSave }) => {
  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: advertisement || noActionAdvertisementFormDefaults(),
    validate: validate(validationSchema),
    onSubmit: onSave,
  });

  const { values, touched, isSubmitting, errors } = formik;
  return (
    <Form className="w-100" onSubmit={formik.handleSubmit}>
      <div className="topbar d-sm-flex justify-content-sm-between">
        <div className="col-heading">
          <Link className="btn btn-back" to={linkBack}></Link>
          <Box ml={5} width={'50%'}>
            <ScutiInput
              name="name"
              placeholder="Advertisement name"
              value={values.name}
              error={touched.name && errors.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Box>
        </div>
        <div className="col-action">
          <Button color="outline-secondary" onClick={formik.handleReset} disabled={!formik.dirty}>
            Cancel
          </Button>
          <Button color="primary" type="submit" disabled={isSubmitting}>
            Save changes
          </Button>
        </div>
      </div>
      <Box pb={2}>
        <Grid container spacing={4}>
          <Grid item container spacing={4}>
            <Grid item xs={12} md={6} lg={4}>
              <NoActionAdvertisementDetails {...formik} />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Form>
  );
};
