import { Card, CardContent } from '@material-ui/core';
import { Box, CircularProgress } from '@mui/material';
import { analyticsFetcher } from 'api/analytics-api';
import { useEffect, useMemo, useState } from 'react';
import moment, { ISO_8601 } from 'moment';
import { Bar } from 'react-chartjs-2';
import { useQuery } from 'react-query';
import { AnalyticsPeriod, AnalyticsRange, campaignColors } from 'types/app-types';
import { CampaignType } from 'types/__generated__/types';
import { ScutiSelect } from '../ScutiSelect';

const campaignTypes = [CampaignType.Banner, CampaignType.Video, CampaignType.Product, CampaignType.ProductListing];

type OfferImpressionsResponse = {
  datePeriod: string; // 2022-01
  items:
    | {
        count: number;
        campaignType: CampaignType;
      }[]
    | null;
}[];

const options = {
  maintainAspectRatio: false,
  cornerRadius: 2,
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        gridLines: {},
        stacked: true,
        ticks: {
          fontColor: '#53555B',
        },
      },
    ],
    xAxes: [
      {
        maxBarThickness: 10,
        stacked: true,
        gridLines: {
          color: 'transparent',
        },
        ticks: {
          fontColor: '#53555B',
        },
      },
    ],
  },
};

const suffixes = new Map([
  ['one', 'st'],
  ['two', 'nd'],
  ['few', 'rd'],
  ['other', 'th'],
]);

var pr = new Intl.PluralRules('en-US', { type: 'ordinal' });

const getLabels = (response: OfferImpressionsResponse, groupBy: AnalyticsPeriod) => {
  if (groupBy === 'year')
    return response.map(({ datePeriod }) => {
      const [year] = datePeriod.split('-');
      return year;
    });
  if (groupBy === 'month')
    return response.map(({ datePeriod }) => {
      const [, month] = datePeriod.split('-');
      return moment()
        .month(parseInt(month) - 1)
        .format('MMM');
    });
  if (groupBy === 'week')
    return response.map(({ datePeriod }) => {
      const [, week] = datePeriod.split('-');
      const n = parseInt(week);
      const rule = pr.select(n);
      const suffix = suffixes.get(rule);
      return `${n}${suffix} week`;
    });
  if (groupBy === 'day')
    return response.map(({ datePeriod }) => moment(datePeriod, ISO_8601).format('ddd (MM-DD-YYYY)'));
  if (groupBy === 'hour') return response.map(({ datePeriod }) => moment(datePeriod, ISO_8601).format('hh a'));
  return [];
};

const calculateDatasets = (response: OfferImpressionsResponse) => {
  return campaignTypes.map(ct => {
    const { name, color } = campaignColors[ct];
    return {
      label: name,
      backgroundColor: color,
      data: response.map(({ items }) => {
        return (items || []).find(item => item.campaignType === ct)?.count || 0;
      }),
    };
  });
};

const mapGraphData = (response: OfferImpressionsResponse, groupBy: AnalyticsPeriod) => {
  return {
    circular: true,
    labels: getLabels(response, groupBy),
    datasets: calculateDatasets(response),
  };
};

interface Props {
  range: AnalyticsRange;
  shopId: string;
}

export const OfferImpressionsShop: React.FC<Props> = ({ range, shopId }) => {
  const periodOptions = useMemo<{ value: AnalyticsPeriod; label: AnalyticsPeriod }[]>(() => {
    const diff = Math.round(range.to.diff(range.from, 'days', true));
    if (diff > 365) {
      return [
        { value: 'year', label: 'year' },
        { value: 'month', label: 'month' },
      ];
    } else if (diff > 28) {
      return [
        { value: 'month', label: 'month' },
        { value: 'week', label: 'week' },
      ];
    } else if (diff >= 7) {
      return [
        { value: 'week', label: 'week' },
        { value: 'day', label: 'day' },
      ];
    } else if (diff > 1) {
      return [{ value: 'day', label: 'day' }];
    }
    return [{ value: 'hour', label: 'hour' }];
  }, [range]);

  const [groupBy, setGroupBy] = useState<AnalyticsPeriod>(periodOptions[0].value);

  const useOfferImpressions = useQuery<OfferImpressionsResponse>(
    [range, shopId, groupBy],
    analyticsFetcher('shop')<OfferImpressionsResponse>('shop-impressions', {
      date_from: range.from.format('YYYY-MM-DD'),
      // Add additional day to cover last minutes of `to` date
      date_to: range.to
        .clone()
        .add(1, 'day')
        .format('YYYY-MM-DD'),
      group_by: groupBy,
      id: shopId,
    }),
  );

  useEffect(() => {
    setGroupBy(periodOptions[0].value);
  }, [periodOptions]);

  const graphData = useMemo(() => {
    // remove last item from array such as it's include next day data
    return mapGraphData(useOfferImpressions.data?.slice(0, -1) || [], groupBy);
  }, [groupBy, useOfferImpressions.data]);

  const showGroupBy = periodOptions.length > 1;

  return (
    <Card>
      <CardContent>
        <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
          <Box fontWeight={700} mb={2}>
            Offer Impressions
          </Box>
          {showGroupBy && (
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Box fontWeight="bold">Group by</Box>
              <Box ml={1} width={150}>
                <ScutiSelect
                  options={periodOptions}
                  value={groupBy}
                  onChange={({ target }) => setGroupBy(target.value as AnalyticsPeriod)}
                />
              </Box>
            </Box>
          )}
        </Box>
        <Box height={310}>
          {useOfferImpressions.isLoading ? (
            <Box display="flex" width="100%" height="100%" alignItems="center" justifyContent="center">
              <CircularProgress color="inherit" />
            </Box>
          ) : (
            <Bar data={graphData} options={options} height={50} />
          )}
        </Box>
        <Box display="flex" mt={2} ml={5} mb={5}>
          {graphData.datasets.map(({ label, backgroundColor }, i) => {
            return (
              <Box key={i} display="flex" alignItems="center" mb={1} ml={!!i ? 2 : 0}>
                <Box width={20} height={20} mr={1} style={{ backgroundColor: backgroundColor, borderRadius: 4 }} />
                <Box style={{ color: '#9DA5B1' }}>{label}</Box>
              </Box>
            );
          })}
        </Box>
      </CardContent>
    </Card>
  );
};
