import React from 'react';
import { observer } from 'mobx-react-lite';
import { Card, CardHeader, CardContent, Box, Grid, Typography } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { FormikProps } from 'formik';
import { GameForm } from './GameEditForm.utils';
import { ScutiImgInput } from 'components/material/ScutiImgInput';
import ImageIcon from '@mui/icons-material/Image';
import { scutiColors } from 'material-theme/variables';

export const GameEditCurrency: React.FC<FormikProps<GameForm>> = observer(props => {
  const { values, touched, handleChange, handleBlur, setFieldValue } = props;
  const errors = props.errors as any;
  const currencyInputError = touched.currency ? errors?.currency?.exchangeRate : '';
  return (
    <Card>
      <CardHeader title="Currency" />
      <CardContent>
        <Box>
          <ScutiInput
            title="Currency Name"
            type="text"
            name="currency.name"
            placeholder="What is the name of the currency"
            error={touched.currency && errors?.currency?.name}
            value={values.currency?.name || ''}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Box>
        <Box mt={2}>
          <Grid container item alignItems="flex-end" xs={10}>
            <Grid item xs={5}>
              <ScutiInput
                title="Currency value"
                type="number"
                name="currency.exchangeRate"
                placeholder="What is the exchangeRate of the game"
                error={touched.currency && errors?.currency?.exchangeRate}
                value={values.currency?.exchangeRate || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                hideHelper
              />
            </Grid>
            {!!values.id && <Box mr={0.2} ml={0.2} className="icon-equally icon-sm" />}
            <Grid item xs={5}>
              {!!values.id && (
                <ScutiInput
                  title="Exchange Rate"
                  type="number"
                  name="currency.scutiExchangeRate"
                  disabled
                  value={values.currency?.scutiExchangeRate || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              )}
            </Grid>
            {currencyInputError ? (
              <Box mt={1} color="error">
                <Typography color="error" variant="caption">
                  {currencyInputError}
                </Typography>
              </Box>
            ) : null}
          </Grid>
        </Box>
        <Box mt={2} display="flex" justifyContent="space-between" alignItems="flex-start">
          <Box width="4rem" height="4rem" flex="0 0 auto" mr={2}>
            <ScutiImgInput
              image={values.currency?.thumbnail}
              onChange={file => setFieldValue('currency.thumbnail', file, false)}
              error={errors.currency?.thumbnail}
            >
              <ImageIcon color="secondary" style={{ width: '100%', height: '100%' }} />
            </ScutiImgInput>
          </Box>
          <Box width="80%" color={scutiColors.grey200}>
            <label>Currency Thumbnail</label>
            <Typography variant="body2">The thumbnail image for your currency.</Typography>
            <Typography variant="body2">Upload a 100 x 100px or 1:1 aspect ratio image.</Typography>
          </Box>
        </Box>
        <Box textAlign="left">
          <Typography color="error" variant="caption">
            {errors.currency?.thumbnail}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
});
