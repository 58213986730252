import React, { useCallback } from 'react';
import { FormControl, TextField, TextFieldProps } from '@material-ui/core';
import { Tooltip } from '@mui/material';
import { useToasts } from 'react-toast-notifications';
import { UpdateOrderInput } from '../../types/__generated__/types';
import { OrderFragment, useSetNoteOrderMutation } from '../../pages/Orders/__generated__/orders.hooks';

interface Props extends Omit<TextFieldProps, 'error' | 'helperText'> {
  title?: string;
  error?: React.ReactNode;
  hideHelper?: boolean;
  initialValue: string;
  order: OrderFragment;
}

export const ScutiInputNoteOrder: React.FC<Props> = ({ title, error, hideHelper, initialValue, order, ...props }) => {
  const [value, setValue] = React.useState(initialValue);
  const { addToast, removeAllToasts } = useToasts();
  const [updateInput, setUpdateInput] = React.useState<UpdateOrderInput>({
    note: '',
  });
  const useNoteOrder = useSetNoteOrderMutation();

  const onChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setValue(e.target.value);
  };

  const handleVendorOrderChange = useCallback(
    async (order: OrderFragment, note: string) => {
      removeAllToasts();
      updateInput.note = note;
      updateInput.ebridgeOrderId = order.ebridgeOrderId;
      updateInput.vendorOrderId = order.vendorOrderId;
      updateInput.payoutStatus = order.payoutStatus;
      setUpdateInput(updateInput);
      try {
        await useNoteOrder.mutateAsync({
          id: order.id,
          input: updateInput,
        });
        addToast(' Note Value: ' + value + '  updated successful ', {
          appearance: 'success',
          autoDismiss: true,
          placement: 'top-center',
        });
      } catch (error) {
        console.error(error);
        const message = 'Order # ' + order.id + ' had issues with update note ';
        addToast(message, { appearance: 'error', autoDismiss: true, autoDismissTimeout: 10000 });
      }
    },
    [useNoteOrder, addToast, setUpdateInput, removeAllToasts],
  );

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === 'Enter') {
      handleVendorOrderChange(order, value).then(r => {});
    }
  };

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <FormControl fullWidth>
      {title && <label style={{ opacity: props.disabled ? 0.4 : 1 }}>{title}</label>}
      <Tooltip title="Please enter for input">
        <TextField
          fullWidth
          size="small"
          multiline={true}
          rows={3}
          variant="outlined"
          error={!!error}
          helperText={hideHelper ? undefined : error}
          value={value}
          onKeyDown={handleKeyDown}
          onChange={onChange}
          {...props}
        />
      </Tooltip>
    </FormControl>
  );
};
