import * as Types from '../../../types/__generated__/types';

import { ApolloError } from 'apollo-client';
import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from 'react-query';
import { fetcher } from 'api';
import { PayoutStatus } from '../../../types/__generated__/types';
export type OrderListItemFragment = {
  id: string;
  ebridgeOrderId: string;
  vendorOrderId: string;
  createdAt: any;
  amount: number;
  status: Types.OrderStatus;
  note?: string;
  user: { id: string; fullName?: Types.Maybe<string>; email: string };
  game: { name: string };
  items: Array<{ quantity: number }>;
};

export type OrderProductFragment = {
  id: string;
  name: string;
  description?: Types.Maybe<string>;
  category?: Types.Maybe<string>;
  variant: {
    id: string;
    image?: Types.Maybe<string>;
    sku: string;
    barcode?: Types.Maybe<string>;
    inStock: number;
    price: { currency: string; amount: number };
    compareAt?: Types.Maybe<{ currency: string; amount: number }>;
    weight?: Types.Maybe<{ amount: number; unit: Types.WeightUnit }>;
  };
};

export type OrderFragment = {
  id: string;
  createdAt: any;
  amount: number;
  status: Types.OrderStatus;
  note?: string;
  ebridgeOrderId?: string;
  vendorOrderId?: string;
  payoutStatus: Types.PayoutStatus;
  user: { id: string; email: string; fullName?: Types.Maybe<string> };
  shippingInformation?: Types.Maybe<{
    phone?: Types.Maybe<string>;
    address1: string;
    address2?: Types.Maybe<string>;
    city: string;
    zipCode: string;
    country: string;
    state?: Types.Maybe<string>;
  }>;
  items: Array<{
    id: string;
    amount: number;
    quantity: number;
    fulfillmentMethod: { name: string; type: Types.FulfillmentType };
    product: OrderProductFragment;
  }>;
};

export type ShopOrderQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type ShopOrderQuery = { order: OrderFragment };

export type OrdersQueryVariables = Types.Exact<{
  filters?: Types.Maybe<Array<Types.FilterInput> | Types.FilterInput>;
  sorting?: Types.Maybe<Array<Types.SortFieldInput> | Types.SortFieldInput>;
  paging?: Types.Maybe<Types.PagingInput>;
  id: Types.Scalars['ID'];
}>;

export type OrdersQuery = {
  shopOrders: { paging: { offset: number; limit: number; totalCount: number }; nodes: Array<OrderListItemFragment> };
};

export type SetOrderStatusMutationVariables = Types.Exact<{
  status: Types.OrderStatus;
  id: Types.Scalars['String'];
}>;

export type SetOrderStatusMutation = { setOrderStatus: { id: string; status: Types.OrderStatus } };

export const OrderListItemFragmentDoc = `
    fragment OrderListItem on Order {
  id
  note
  payoutStatus
  ebridgeOrderId
  vendorOrderId
  createdAt
  amount
  user {
    id
    fullName
    email
  }
  game {
    name
  }
  status
  items {
    quantity
  }
}
    `;
export const OrderProductFragmentDoc = `
    fragment OrderProduct on OrderProductSnapshot {
  id
  name
  description
  category
  variant {
    id
    image
    price {
      currency
      amount
    }
    compareAt {
      currency
      amount
    }
    sku
    barcode
    weight {
      amount
      unit
    }
    inStock
  }
}
    `;
export const OrderFragmentDoc = `
    fragment Order on Order {
  id
  ebridgeOrderId
  vendorOrderId
  payoutStatus
  note
  createdAt
  user {
    id
    email
    fullName
  }
  amount
  shippingInformation {
    phone
    address1
    address2
    city
    zipCode
    country
    state
  }
  items {
    id
    fulfillmentMethod {
      name
      type
    }
    amount
    quantity
    product {
      ...OrderProduct
    }
  }
  status
}
    ${OrderProductFragmentDoc}`;
export const ShopOrderDocument = `
    query ShopOrder($id: String!) {
  order(id: $id) {
    ...Order
  }
}
    ${OrderFragmentDoc}`;
export const useShopOrderQuery = <TData = ShopOrderQuery, TError = ApolloError>(
  variables: ShopOrderQueryVariables,
  options?: UseQueryOptions<ShopOrderQuery, TError, TData>,
) =>
  useQuery<ShopOrderQuery, TError, TData>(
    ['ShopOrder', variables],
    fetcher<ShopOrderQuery, ShopOrderQueryVariables>(ShopOrderDocument, variables),
    options,
  );
export const OrdersDocument = `
    query Orders($filters: [FilterInput!], $sorting: [SortFieldInput!], $paging: PagingInput, $id: ID!) {
  shopOrders(filters: $filters, sorting: $sorting, paging: $paging, id: $id) {
    paging {
      offset
      limit
      totalCount
    }
    nodes {
      ...OrderListItem
    }
  }
}
    ${OrderListItemFragmentDoc}`;
export const useOrdersQuery = <TData = OrdersQuery, TError = ApolloError>(
  variables: OrdersQueryVariables,
  options?: UseQueryOptions<OrdersQuery, TError, TData>,
) =>
  useQuery<OrdersQuery, TError, TData>(
    ['Orders', variables],
    fetcher<OrdersQuery, OrdersQueryVariables>(OrdersDocument, variables),
    options,
  );
export const SetOrderStatusDocument = `
    mutation SetOrderStatus($status: OrderStatus!, $id: String!) {
  setOrderStatus(status: $status, id: $id) {
    id
    status       
  }
}
    `;
export const useSetOrderStatusMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<SetOrderStatusMutation, TError, SetOrderStatusMutationVariables, TContext>,
) =>
  useMutation<SetOrderStatusMutation, TError, SetOrderStatusMutationVariables, TContext>(
    (variables?: SetOrderStatusMutationVariables) =>
      fetcher<SetOrderStatusMutation, SetOrderStatusMutationVariables>(SetOrderStatusDocument, variables)(),
    options,
  );

export type SetNoteOrderVariables = Types.Exact<{
  input: Types.UpdateOrderInput;
  id: Types.Scalars['String'];
}>;
export const SetNoteOrderIDDocument = `
  mutation  updateOrder($input: UpdateOrderInput!, $id: String!) {
   updateOrder(input: $input,id: $id) {
    id
    note
    vendorOrderId
  }
}
 `;

export type SetNoteOrderMutation = { setOrderStatus: { note: string; id: string } };
export const useSetNoteOrderMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<SetNoteOrderMutation, TError, SetNoteOrderVariables, TContext>,
) =>
  useMutation<SetNoteOrderMutation, TError, SetNoteOrderVariables, TContext>(
    (variables?: SetNoteOrderVariables) =>
      fetcher<SetNoteOrderMutation, SetNoteOrderVariables>(SetNoteOrderIDDocument, variables)(),
    options,
  );

export type SetVendorOrderVariables = Types.Exact<{
  vendorOrderId: Types.Scalars['String'];
  id: Types.Scalars['String'];
}>;

export type SetVendorOrderMutation = { setOrderStatus: { vendorOrderId: string; id: string; ebridgeOrderId: string } };
export const SetVendorOrderIDDocument = `
  mutation SetVendorOrderID($vendorOrderId: String!, $id: String!) {
  setVendorOrderID(vendorOrderId: $vendorOrderId,id: $id) {
    id
    ebridgeOrderId
    vendorOrderId
  }
}
 `;

export const useSetVendorOrderMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<SetVendorOrderMutation, TError, SetOrderStatusMutationVariables, TContext>,
) =>
  useMutation<SetVendorOrderMutation, TError, SetOrderStatusMutationVariables, TContext>(
    (variables?: SetOrderStatusMutationVariables) =>
      fetcher<SetVendorOrderMutation, SetOrderStatusMutationVariables>(SetVendorOrderIDDocument, variables)(),
    options,
  );

export type SetRefundOrderMutationVariables = Types.Exact<{
  input: Types.RefundOrderInput;
}>;

export type SetRefundOrderMutation = { setOrderStatus: { vendorOrderId: string; id: string; ebridgeOrderId: string } };
export const SetRefundOrderDocument = `
  mutation produceRefundOrder($input: RefundOrderInput!) {
 produceRefundOrder(input: $input) {
  userId          
  orderId   
     orderItems{
       refundQuantity
       currencyName
       scutis
       activated  
     }
 } 
}
 `;

export const useSetRefundOrderMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<SetRefundOrderMutation, TError, SetRefundOrderMutationVariables, TContext>,
) =>
  useMutation<SetRefundOrderMutation, TError, SetRefundOrderMutationVariables, TContext>(
    (variables?: SetRefundOrderMutationVariables) =>
      fetcher<SetRefundOrderMutation, SetRefundOrderMutationVariables>(SetRefundOrderDocument, variables)(),
    options,
  );

export type RefundOrdersQuery = { myRefundOrder: RefundOrderFragment };

export type RefundOrdersQueryVariables = Types.Exact<{
  orderId: Types.Scalars['String'];
  userId: Types.Scalars['String'];
}>;

export type RefundOrderFragment = {
  orderId: string;
  userId: string;
  orderItems: Array<{
    orderItemId: string;
    refundQuantity: number;
    activated: boolean;
    reason: string;
  }>;
};

export const GetRefundOrderDocument = `
  query myRefundOrder($orderId: String! , $userId:String!) {
  myRefundOrder(orderId: $orderId, userId: $userId) {
    userId
    orderId
    orderItems{
      orderItemId
      refundQuantity
      activated
      reason
    }
  }
   }
 `;
export const useRefundOrdersQuery = <TData = RefundOrdersQuery, TError = ApolloError>(
  variables?: RefundOrdersQueryVariables,
  options?: UseQueryOptions<RefundOrdersQuery, TError, TData>,
) =>
  useQuery<RefundOrdersQuery, TError, TData>(
    ['myRefundOrder', variables],
    fetcher<RefundOrdersQuery, RefundOrdersQueryVariables>(GetRefundOrderDocument, variables),
    options,
  );
