import React from 'react';
import { FormikProps } from 'formik';
import { Box, Typography, Card, CardHeader, CardContent } from '@material-ui/core';
import { CampaignForm, isPaused } from './CampaignForm.utils';
import { ScutiImgInput } from 'components/material/ScutiImgInput';
import { scutiColors } from 'material-theme/variables';

export const AppDownloadMediaDetails: React.FC<FormikProps<CampaignForm>> = props => {
  const { values, touched, errors, setFieldValue } = props;
  return (
    <Card>
      <CardHeader title="App Download Media" />
      <CardContent>
        <Box>
          <Box width="100%" height="4rem">
            <ScutiImgInput
              image={values.media?.banner}
              // @ts-ignore
              error={!!errors.media?.banner}
              onChange={file => setFieldValue('media.banner', file, true)}
              disabled={!isPaused(values)}
            >
              <Box
                height="100%"
                pl="2rem"
                pr="2rem"
                display="flex"
                flexDirection="column"
                color={scutiColors.grey200}
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="body2">Upload a 300x600px or 1:2 aspect ratio image for your banner.</Typography>
              </Box>
            </ScutiImgInput>
            <Box mb={2}>
              {/* @ts-ignore */}
              {touched.media?.banner && (
                <Typography color="error" variant="caption">
                  {/* @ts-ignore */}
                  {errors.media?.banner}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
        <Box mt={4}>
          <Box width="100%" height="4rem">
            <ScutiImgInput
              image={values.media?.tile}
              // @ts-ignore
              error={!!errors.media?.tile}
              onChange={file => setFieldValue('media.tile', file, true)}
              disabled={!isPaused(values)}
            >
              <Box
                height="100%"
                pl="2rem"
                pr="2rem"
                display="flex"
                flexDirection="column"
                color={scutiColors.grey200}
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="body2">Upload a 300x250px or 1.2:1 aspect ratio image for your banner.</Typography>
              </Box>
            </ScutiImgInput>
            <Box mb={2}>
              {/* @ts-ignore */}
              {touched.media?.tile && (
                <Typography color="error" variant="caption">
                  {/* @ts-ignore */}
                  {errors.media?.tile}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
        <Box mt={4}>
          <Box width="100%" height="4rem">
            <ScutiImgInput
              image={values.media?.vertical}
              // @ts-ignore
              error={!!errors.media?.vertical}
              onChange={file => setFieldValue('media.vertical', file, true)}
              disabled={!isPaused(values)}
            >
              <Box
                height="100%"
                pl="2rem"
                pr="2rem"
                display="flex"
                flexDirection="column"
                color={scutiColors.grey200}
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="body2">Upload a 728x90px or 8.09:1 aspect ratio image for your banner.</Typography>
              </Box>
            </ScutiImgInput>
            <Box>
              <Typography color="error" variant="caption">
                {/* @ts-ignore */}
                {errors.media?.vertical}
              </Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
