import React from 'react';
import { FormikProps } from 'formik';
import { Box, Card, CardHeader, CardContent } from '@material-ui/core';
import { AdvertisementForm } from './AdvertisementForm.utils';
import { ScutiSelect } from 'components/material/ScutiSelect';
import { ScutiInput } from 'components/material/ScutiInput';
import { useProductCategories } from 'hooks/useProductCategories';

export const AppDownloadAdvertisementDetails: React.FC<FormikProps<AdvertisementForm>> = props => {
  const { values, touched, errors, handleBlur, handleChange } = props;

  const productCategoriesOptions = useProductCategories();
  return (
    <Card>
      <CardHeader title="Details" />
      <CardContent>
        <Box mt={2}>
          <ScutiInput
            title="CPA"
            name="cpa"
            type="number"
            placeholder="$1.00"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.cpa || ''}
            error={values.cpa ? errors['cpa'] : touched.cpa && errors['cpa']}
          />
        </Box>
        <Box mt={2}>
          <ScutiSelect
            name="category"
            title="Category *"
            placeholder="Select category"
            value={values.category}
            error={touched.category && errors.category}
            onChange={handleChange}
            onBlur={handleBlur}
            options={productCategoriesOptions}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
