import React from 'react';
import { Card, FormGroup } from 'reactstrap';
import { FormikProps } from 'formik';
import { CardHeader, CardContent } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { CampaignForm, isPaused } from './CampaignForm.utils';

export const RewardDetails: React.FC<FormikProps<CampaignForm> & { showInventory?: boolean }> = ({
  touched,
  errors,
  values,
  handleChange,
  handleBlur,
}) => {
  return (
    <Card className="bg-custom">
      <CardHeader title="Rewards" />
      <CardContent>
        <FormGroup className="pt-6">
          <label htmlFor={`reward.scutiPercentage`}>
            <ScutiInput
              type="number"
              title="Scuti Rewards Percentage"
              name={`reward.scutiPercentage`}
              placeholder="% of Scuti$"
              // @ts-ignore
              error={touched.reward?.scutiPercentage && errors.reward?.scutiPercentage}
              value={values.reward?.scutiPercentage || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              inputProps={{
                min: 0,
                max: 100,
              }}
              disabled={!isPaused(values)}
            />
          </label>
        </FormGroup>
      </CardContent>
    </Card>
  );
};
