import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { AdvertisementType } from 'types/__generated__/types';
// import { CampaignType } from 'types/__generated__/types';

interface Props {
  linkBack: string;
  onSelect: (params: any | null) => void;
}

export const CreateAdvertisement: React.FC<Props> = ({ linkBack, onSelect }) => {
  const selectAdvertisementType = React.useCallback((type: any | null) => () => onSelect(type), [onSelect]);

  return (
    <div className="animated fadeIn popup-holder d-flex justify-content-center align-items-center">
      <div className="campaign-creation">
        <Link className="btn btn-back" to={linkBack}></Link>
        <div className="heading">
          <h2>New Advertisement</h2>
          <p>Please select the advertisement type you'd like to create below.</p>
        </div>
        <div className="campaign-types">
          <Row className="gap-md">
            <Col sm={6}>
              <div className="campaign-types-item d-flex wh-100">
                <div
                  className="campaign-types-link d-flex wh-100"
                  onClick={selectAdvertisementType(AdvertisementType.AppDownload)}
                >
                  <i className="campaign-types-icon align-self-center">
                    <span className="icon-appdownloadcampaign"></span>
                  </i>
                  <div className="campaign-types-title">
                    <strong>App Download</strong>
                    <em>Promote your app</em>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={6}>
              <div className="campaign-types-item d-flex wh-100">
                <div
                  className="campaign-types-link d-flex wh-100"
                  onClick={selectAdvertisementType(AdvertisementType.VideoPromo)}
                >
                  <i className="campaign-types-icon align-self-center">
                    <span className="icon-videopromo"></span>
                  </i>
                  <div className="campaign-types-title">
                    <strong>Video Promo</strong>
                    <em>Create a Video Advertisement</em>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={6}>
              <div className="campaign-types-item d-flex wh-100">
                <div
                  className="campaign-types-link d-flex wh-100"
                  onClick={selectAdvertisementType(AdvertisementType.LeadGen)}
                >
                  <i className="campaign-types-icon align-self-center">
                    <span className="icon-productcampaign"></span>
                  </i>
                  <div className="campaign-types-title">
                    <strong>Lead Gen</strong>
                    <em>Create a Lead Gen Advertisement</em>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={6}>
              <div className="campaign-types-item d-flex wh-100">
                <div
                  className="campaign-types-link d-flex wh-100"
                  onClick={selectAdvertisementType(AdvertisementType.Coupon)}
                >
                  <i className="campaign-types-icon align-self-center">
                    <span className="icon-bannerad"></span>
                  </i>
                  <div className="campaign-types-title">
                    <strong>Coupon</strong>
                    <em>Create a Coupon Offer</em>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={6}>
              <div className="campaign-types-item d-flex wh-100">
                <div
                  className="campaign-types-link d-flex wh-100"
                  onClick={selectAdvertisementType(AdvertisementType.NoActionAd)}
                >
                  <i className="campaign-types-icon align-self-center">
                    <span className="icon-bannerad"></span>
                  </i>
                  <div className="campaign-types-title">
                    <strong>No Action Ad</strong>
                    <em>Create a non-action Ad</em>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={6}>
              <div className="campaign-types-item d-flex wh-100">
                <div
                  className="campaign-types-link d-flex wh-100"
                  onClick={selectAdvertisementType(AdvertisementType.Internal)}
                >
                  <i className="campaign-types-icon align-self-center">
                    <span className="icon-bannerad"></span>
                  </i>
                  <div className="campaign-types-title">
                    <strong>Internal</strong>
                    <em>Create an ad to an internal URL (SCUI ONLY)</em>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
