import React, { useCallback } from 'react';
import { FormFeedback, Button } from 'reactstrap';
import { Tooltip } from '@mui/material';
import { REFUND_REASONS_SELECT_OPTIONS } from 'types/select-types';
import { useToasts } from 'react-toast-notifications';
import { ScutiSelect } from 'components/material/ScutiSelect';
import {
  OrderFragment,
  RefundOrderFragment,
  RefundOrdersQuery,
  useRefundOrdersQuery,
  useSetRefundOrderMutation,
  useShopOrderQuery,
} from '../__generated__/orders.hooks';
import { Card, CardContent, CardHeader, Grid, Box } from '@material-ui/core';
import { ScutiInputRefundOrder } from '../../../components/material/ScutiInputRefundOrder';
import { OrderStatus, RefundOrderInput, RefundOrderInputItem } from '../../../types/__generated__/types';

interface Props {
  order: OrderFragment;
}

export const OrderRefunds: React.FC<Props> = ({ order }) => {
  const [disabled, setDisabled] = React.useState(false);
  const [reason, setReason] = React.useState('Other');
  const { addToast } = useToasts();

  const useSetRefundOrder = useSetRefundOrderMutation();

  const useRefundOrders = useRefundOrdersQuery(
    { orderId: order.id, userId: order.user.id },
    {
      onSuccess: ({ myRefundOrder }) => setInitValues(myRefundOrder),
    },
  );
  const usedRefOrder = useRefundOrders.data?.myRefundOrder || null;
  const setInitValues = (orderRef: RefundOrderFragment) => {
    if (orderRef) {
      setReason(orderRef.orderItems[0].reason);
      const bDisable = orderRef.orderItems.some(item => {
        return item.activated === true;
      });
      setDisabled(bDisable);
    }
  };

  const simpleResonChange = (value: string) => {
    setReason(value);
  };

  const activateRefund = React.useCallback(
    (order: OrderFragment, cases: string, useRefundOrder: any) => async () => {
      const items: RefundOrderInputItem[] = order.items.map(item => {
        return {
          orderItemId: item.id,
          activated: true,
          currencyName: 'USD',
        };
      });
      const refundInput: RefundOrderInput = {
        userId: order.user.id,
        orderId: order.id,
        items: items,
      };

      try {
        await useRefundOrder.mutateAsync({
          input: refundInput,
        });
        setDisabled(true);
        addToast(' refund  activated successful ', {
          appearance: 'success',
          autoDismiss: true,
          placement: 'top-center',
        });
      } catch (error) {
        console.error(error);
        const message = 'Refund for Order Id# ' + refundInput.orderId + 'did not update properly  ';
        addToast(message, { appearance: 'error', autoDismiss: true, autoDismissTimeout: 10000 });
      }
    },
    [useSetRefundOrderMutation, addToast],
  );

  return (
    <Card>
      <CardHeader title="Amount to refund" />
      <CardContent>
        <Grid container>
          {order.items.map(item => {
            // @ts-ignore
            return (
              <Box key={item.id} display="flex" width="100%" justifyContent="space-between" alignItems="center">
                <Box>
                  <label htmlFor={`reason`}>Available to refund: ${item.amount}</label>
                </Box>
                <Box>
                  <ScutiInputRefundOrder
                    placeholder="refund amount"
                    orderId={order.id}
                    orderItemId={item.id}
                    userId={order.user.id}
                    reason={reason}
                    initialValue={
                      usedRefOrder !== null
                        ? String(
                            // @ts-ignore
                            usedRefOrder.orderItems.find(r => {
                              return r.orderItemId === item.id;
                            }).refundQuantity,
                          )
                        : '0.0'
                    }
                    useRefundOrder={useSetRefundOrder}
                    disabled={disabled}
                  />
                  <FormFeedback>{}</FormFeedback>
                </Box>
              </Box>
            );
          })}
        </Grid>
        <Box mt={3}>
          <Grid container>
            <Grid item xs={4}>
              <Box mb={1} fontWeight="bold">
                Reason for refund (optional)
              </Box>
              <ScutiSelect
                style={{ minWidth: 290 }}
                placeholder="Select reason"
                name="reason"
                disabled={disabled}
                value={reason}
                options={REFUND_REASONS_SELECT_OPTIONS}
                onChange={event => simpleResonChange(event.target.value as string)}
              />
            </Grid>
          </Grid>
        </Box>
        <Tooltip title="Please enter values before refund activate">
          <Box mt={4} width="100%" display="flex" justifyContent="flex-end">
            <Button color="light" onClick={activateRefund(order, reason, useSetRefundOrder)} disabled={disabled}>
              Refund activate
            </Button>
          </Box>
        </Tooltip>
      </CardContent>
    </Card>
  );
};
