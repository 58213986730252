import React from 'react';
import { ScutiInput } from 'components/material/ScutiInput';
import { FormikProps } from 'formik';
import { ScutiSelect } from 'components/material/ScutiSelect';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { useProductCategories } from 'hooks/useProductCategories';

export const CouponAdvertisementDetails: React.FC<FormikProps<any>> = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
}) => {
  const productCategoriesOptions = useProductCategories();
  return (
    <Card>
      <CardHeader title="Details" />
      <CardContent>
        <Box>
          <ScutiInput
            title="Label"
            name="coupon.couponLabel"
            placeholder="Title of coupon"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.coupon?.couponLabel}
            error={
              // @ts-ignore
              values.id ? errors['coupon.couponLabel'] : touched.coupon?.couponLabel && errors['coupon.couponLabel']
            }
          />
        </Box>
        <Box mt={2}>
          <ScutiInput
            title="Description of Coupon"
            name="coupon.couponDescription"
            multiline
            rows={8}
            placeholder="Add a description to your coupon offer"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.coupon?.couponDescription || ''}
            error={
              values.id
                ? errors['coupon.couponDescription']
                : // @ts-ignore
                  touched.coupon?.couponDescription && errors['coupon.couponDescription']
            }
          />
        </Box>
        <Box mt={2}>
          <ScutiInput
            title="CPA"
            name="cpa"
            type="number"
            placeholder="$1.00"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.cpa || ''}
            error={values.id ? errors['cpa'] : touched.cpa && errors['cpa']}
          />
        </Box>
        <Box mt={2}>
          <ScutiSelect
            name="category"
            title="Category *"
            placeholder="Select category"
            value={values.category}
            error={touched.category && errors.category}
            onChange={handleChange}
            onBlur={handleBlur}
            options={productCategoriesOptions}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
